import { Axis, Grid } from "@visx/xychart";
import { formatDate } from "../../utils/utils";
import { timeFormat } from "d3-time-format";
import { AxisProps } from "@visx/xychart/lib/components/axis/Axis";

const gridLineStyle = {
    stroke: "#E2E1EF",
    strokeWidth: 0.5,
};

export const DEFAULT_STROKE = "#655ce9";

export const FULL_GRAPH_WIDTH = 832;
export const FULL_GRAPH_HEIGHT = 264;
export const HALF_GRAPH_WIDTH = 585;
export const HALF_GRAPH_HEIGHT = 168;

export const DISTANCE_X_THRESHOLD = 10;

export const xAccessor = (d: { x: Date }) => d?.x;
export const yAccessor = (d: { y: number }) => d?.y;
export const TICK_LABEL_FILL = "#8D8CA1";

export interface GraphProps {
    width: number;
    height: number;
    startDate: Date;
    endDate: Date;
}

export type StrokeStyle = "solid" | "dotted";

export interface NumberSeriesProps extends GraphProps {
    yDomain?: { min: number; max: number };
}

export function XDateAxis(): JSX.Element {
    return (
        <Axis
            orientation="bottom"
            hideTicks
            numTicks={2}
            stroke="#E2E1EF"
            strokeWidth="1px"
            tickLabelProps={() => ({ fill: TICK_LABEL_FILL })}
            tickFormat={(d: Date) => formatDate(d)}
        />
    );
}

export function YAxis({
    tickFormat,
    scaleTickToFit = false,
}: {
    tickFormat?: AxisProps["tickFormat"];
    scaleTickToFit?: boolean;
}): JSX.Element {
    return (
        <Axis
            orientation="left"
            hideTicks
            numTicks={4}
            tickFormat={tickFormat}
            tickLabelProps={() => ({
                fill: TICK_LABEL_FILL,
                scaleToFit: scaleTickToFit,
            })}
            stroke="#E2E1EF"
            strokeWidth={0.5}
        />
    );
}

export function YDateAxis(): JSX.Element {
    return (
        <Axis
            orientation="left"
            hideTicks
            numTicks={4}
            hideZero
            tickLabelProps={() => ({
                fill: TICK_LABEL_FILL,
                scaleToFit: true,
            })}
            tickFormat={formatDateShort}
            {...gridLineStyle}
        />
    );
}

export const formatDateShort = timeFormat("%b %d %H:%M%p");
export const formatTimeShort = timeFormat("%H:%M%p");
export const formatDateTime = timeFormat("%b %d %Y, %H:%M:%S.%L");

export function Gridline(): JSX.Element {
    return <Grid columns={true} numTicks={4} lineStyle={gridLineStyle} />;
}
