import { useContext, useEffect, useState } from "react";
import { InspectorContext } from "../../context/InspectorProvider";
import { Outlet, useSearchParams, useLocation } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import styles from "./Inspector.module.scss";
import DatasetDetail from "../dataset/DatasetDetail";
import FeatureDetail from "../featureset/FeatureDetail";
import FeaturesetDetail from "../featureset/FeaturesetDetail";
import EntityIcon from "icons/entities.svg";
import { Dataset, Featureset, FeatureProps } from "shared/utils/types";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import { useValidEntity } from "../../context/CurrentView";
import { QueryOfflineDetail } from "../query-offline/QueryOfflineDetail";

const ENTITY_LIST = ["dataset", "feature", "featureset"];
const DETAIL_LIST = [
    {
        key: "query_id",
        path: "queries",
    },
];

const EmptyState = () => {
    return (
        <div className={styles.emptyInspector}>
            <EntityIcon width={"2rem"} height={"2rem"} />
            Select an entity to inspect
        </div>
    );
};

function getInspectorDetail(
    entityType: string,
    entityName: string,
    entity?: Dataset | Featureset | FeatureProps
) {
    switch (entityType) {
        case "dataset":
            return <DatasetDetail dataset={entity as Dataset} />;
        case "feature":
            return <FeatureDetail {...(entity as FeatureProps)} />;
        case "featureset":
            return <FeaturesetDetail featureset={entity as Featureset} />;
        case "query_offline":
            return <QueryOfflineDetail request_id={entityName} />;
        default:
            return <EmptyState />;
    }
}

const defaultProps = {
    entityType: "",
    entityName: "",
    featuresetName: "",
};

const Inspector = () => {
    const { isSidebarOpen, setSidebarOpen } = useContext(InspectorContext);
    const [searchParams] = useSearchParams();
    const [inspectorProps, setInspectorProps] = useState(defaultProps);
    const [detailView, setDetailView] = useState(false);
    const getValidEntity = useValidEntity();
    const location = useLocation();
    const entity = getValidEntity(
        inspectorProps.entityType,
        inspectorProps.entityName,
        inspectorProps.featuresetName
    );

    useEffect(() => {
        let featurePresent = false;
        let featuresetPresent = false;
        let entityPresent = false;
        let maybeFeature = "";
        const tempProps = {
            entityType: "",
            entityName: "",
            featuresetName: "",
        };

        searchParams.forEach((val, key) => {
            if (ENTITY_LIST.includes(key)) {
                entityPresent = true;
                tempProps.entityType = key;
                tempProps.entityName = val;
                if (key === "feature") {
                    featurePresent = true;
                    tempProps.entityType = "";
                    maybeFeature = val;
                }
                if (key === "featureset") {
                    featuresetPresent = true;
                    tempProps.featuresetName = val;
                }
                setDetailView(false);
            } else if (
                DETAIL_LIST.some(
                    (s) => s.key === key && location.pathname.includes(s.path)
                )
            ) {
                entityPresent = true;
                tempProps.entityName = val;
                tempProps.entityType = "query_offline";
                setDetailView(true);
            }
        });

        if (featurePresent && featuresetPresent) {
            tempProps.entityType = "feature";
            tempProps.entityName = maybeFeature;
        }

        if (tempProps.entityType) {
            setInspectorProps(tempProps);
            setSidebarOpen(true);
        }

        if (!entityPresent) {
            setInspectorProps(defaultProps);
            setSidebarOpen(false);
        }
    }, [searchParams]);

    return (
        <AnimatePresence>
            <PanelGroup
                autoSaveId={"console"}
                direction="horizontal"
                className={styles.panelGroup}
            >
                <Panel id="main" order={1} className={styles.panelShadow}>
                    <Outlet />
                </Panel>

                {isSidebarOpen && (
                    <>
                        <PanelResizeHandle className={styles.resizeHandle} />
                        <Panel
                            id="inspect"
                            order={2}
                            minSize={33}
                            defaultSize={33}
                            className={styles.panelShadow}
                        >
                            <motion.div
                                initial={{ x: "100%" }}
                                animate={{ x: 0 }}
                                exit={{ x: "100%" }}
                                transition={{ duration: 0.25 }}
                            >
                                {!entity && !detailView ? (
                                    <EmptyState />
                                ) : (
                                    <div className={styles.inspectorContainer}>
                                        {getInspectorDetail(
                                            inspectorProps.entityType,
                                            inspectorProps.entityName,
                                            entity
                                        )}
                                    </div>
                                )}
                            </motion.div>
                        </Panel>
                    </>
                )}
            </PanelGroup>
        </AnimatePresence>
    );
};

export default Inspector;
