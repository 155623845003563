// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".P2SIgY2thSw5TWcSZWfo{display:flex;gap:8px;align-items:center}.zhrxoxxscJXlTDk1_2mo{opacity:.5}.iDaDQWYsz8ZPgFq47mbH{display:flex;flex-direction:column;padding:2px 0}.HpiFxpJrBj7o4LiZ0EPw{width:1rem;height:1rem}", "",{"version":3,"sources":["webpack://./src/console/shared/components/search/styles/SourcesFilter.module.scss","webpack://./src/console/shared/components/search/styles/_search.scss"],"names":[],"mappings":"AAEA,sBCsBI,YAAA,CACA,OAAA,CACA,kBAAA,CDpBJ,sBACI,UAAA,CAGJ,sBACI,YAAA,CACA,qBAAA,CACA,aAAA,CAGJ,sBACI,UAAA,CACA,WAAA","sourcesContent":["@use \"./search\";\n\n.filter {\n    @include search.filter;\n}\n\n.metadata {\n    opacity: 0.5;\n}\n\n.metadataContainer {\n    display: flex;\n    flex-direction: column;\n    padding: 2px 0;\n}\n\n.sourceIcon{\n    width: 1rem;\n    height: 1rem;\n}","@use \"shared/styles/common\" as *;\n@use \"../../../styles/menu\";\n\n@mixin select-dropdown {\n    position: absolute;\n    top: calc(32px + 10px);\n    left: 0;\n\n    min-width: 240px;\n\n    @include menu.menu;\n\n    .title {\n        font-weight: 600;\n    }\n}\n\n@mixin hover {\n    background: linear-gradient(0deg, var(--muted-foreground-04), var(--muted-foreground-04)), $surface-color;\n    border-radius: 8px;\n    cursor: pointer;\n}\n\n@mixin filter {\n    display: flex;\n    gap: 8px;\n    align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filter": "P2SIgY2thSw5TWcSZWfo",
	"metadata": "zhrxoxxscJXlTDk1_2mo",
	"metadataContainer": "iDaDQWYsz8ZPgFq47mbH",
	"sourceIcon": "HpiFxpJrBj7o4LiZ0EPw"
};
export default ___CSS_LOADER_EXPORT___;
