import { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
    convertToDate,
    DateValue,
} from "shared/components/date-picker/date-types";
import { useSearchParams } from "react-router-dom";

export function usePolling(
    refreshInterval: number,
    startDateValue: DateValue,
    endDateValue: DateValue,
    setDerivedStartDate: Dispatch<SetStateAction<Date>>,
    setDerivedEndDate: Dispatch<SetStateAction<Date>>
): void {
    useEffect(() => {
        if (refreshInterval) {
            const intervalId = setInterval(() => {
                if (typeof startDateValue === "string") {
                    setDerivedStartDate(convertToDate(startDateValue));
                }
                if (typeof endDateValue === "string") {
                    setDerivedEndDate(convertToDate(endDateValue));
                }
            }, refreshInterval);

            return () => clearInterval(intervalId); // Clean up the interval on unmount
        }
    }, [
        refreshInterval,
        startDateValue,
        setDerivedStartDate,
        setDerivedEndDate,
    ]);
}

export function useDerivedDateRange(
    startDateValue: DateValue,
    endDateValue: DateValue
) {
    const [derivedStartDate, setDerivedStartDate] = useState<Date>(
        convertToDate(startDateValue)
    );
    const [derivedEndDate, setDerivedEndDate] = useState<Date>(
        convertToDate(endDateValue)
    );

    useEffect(() => {
        setDerivedStartDate(convertToDate(startDateValue));
        setDerivedEndDate(convertToDate(endDateValue));
    }, [startDateValue, endDateValue]);

    return [
        derivedStartDate,
        derivedEndDate,
        setDerivedStartDate,
        setDerivedEndDate,
    ];
}

export function useParamDateRange() {
    const [searchParams] = useSearchParams();
    const paramFromDate = searchParams.get("fromDate");
    const paramToDate = searchParams.get("toDate");

    return {
        paramFromDate: paramFromDate ? new Date(paramFromDate) : undefined,
        paramToDate: paramToDate ? new Date(paramToDate) : undefined,
    };
}
