import useSWR from "swr";
import styles from "../styles/Ingestion.module.scss";
import {
    axiosFetcherWithParams,
    elapsedTimeFormatterString,
    getElapsedTime,
    getSourceTypeIcon,
    nFormatter,
} from "../../../utils/utils";
import { useParams } from "react-router-dom";
import {
    BatchSinkStatus,
    Dataset,
    DatasetIngestion,
    DatasetIngestionStatus,
    Source,
} from "../../../utils/types";
import ChevronRightIcon from "icons/chevron-right.svg";
import CheckCircleIcon from "icons/check-circle.svg";
import XCircleIcon from "icons/x-circle.svg";
import RowsIngestedIcon from "icons/rows-ingested.svg";
import RowsDroppedIcon from "icons/rows-dropped.svg";
import RowsFailedIcon from "icons/rows-failed.svg";
import RowsFilteredIcon from "icons/rows-filtered.svg";
import DatasetIcon from "icons/dataset.svg";
import Tooltip from "../../Tooltip";
import { parseISO } from "date-fns";
import EmptyState from "../../EmptyState";
import { LastUpdatedTimestamp } from "./LatestTimestamp";
import { ExtractHistoricalJobStatus } from "shared/models";

// Function to filter and select jobs based on the given conditions
/*
If there are both INGESTING and INITIALIZING jobs, display one of each.
If all jobs are COMPLETE, display the latest one.
If there are COMPLETE jobs and some other statuses, display the latest non-complete job.
If all jobs are INITIALIZING, display the latest one.
If all jobs are INGESTING, display the latest one.
If there are jobs with all three statuses, display the latest INITIALIZING and the latest INGESTING job.
*/
const selectJobs = (stats: DatasetIngestion["stats"]) => {
    if (!stats || stats.length === 0) return [];

    const initializingJobs = stats.filter(
        (job) => job.status === DatasetIngestionStatus.INITIALIZING
    );
    const ingestingJobs = stats.filter(
        (job) => job.status === DatasetIngestionStatus.INGESTING
    );
    const completeJobs = stats.filter(
        (job) => job.status === DatasetIngestionStatus.COMPLETE
    );

    if (ingestingJobs.length > 0 && initializingJobs.length > 0) {
        return [ingestingJobs[0], initializingJobs[0]];
    }

    if (completeJobs.length === stats.length) {
        return [completeJobs[0]];
    }

    if (completeJobs.length < stats.length) {
        const nonCompleteJobs = stats.filter(
            (job) => job.status !== DatasetIngestionStatus.COMPLETE
        );
        return [nonCompleteJobs[0]];
    }

    if (initializingJobs.length === stats.length) {
        return [initializingJobs[0]];
    }

    if (ingestingJobs.length === stats.length) {
        return [ingestingJobs[0]];
    }

    if (initializingJobs.length > 0 && ingestingJobs.length > 0) {
        return [initializingJobs[0], ingestingJobs[0]];
    }

    return [];
};

const getElapsedTimeForSource = (
    started_at: string,
    updated_at: string,
    status: DatasetIngestionStatus
) => {
    const timeString =
        elapsedTimeFormatterString(
            getElapsedTime(
                parseISO(started_at).getTime(),
                false,
                status != DatasetIngestionStatus.COMPLETE
                    ? Date.now()
                    : parseISO(updated_at).getTime()
            )
        ) || "< 1 minute";
    const phrase =
        status === DatasetIngestionStatus.COMPLETE
            ? "Finished in "
            : "Elapsed ";
    return phrase + timeString;
};

function IngestionData({
    sources,
    dataset,
}: {
    sources: Source[];
    dataset: Dataset;
}) {
    const datasetName = dataset.name;
    const { branchName } = useParams();

    const sourceFinder = (source_name: string) =>
        sources.find((s) => s.name === source_name) as Source;
    const { data, isLoading } = useSWR<DatasetIngestion>(
        [
            "post",
            `/branch/${branchName}/ingestion_stats`,
            {
                dataset: datasetName,
                last_n: 10,
            },
        ],
        axiosFetcherWithParams,
        { refreshInterval: 120000 }
    );

    const jobsToDisplay = selectJobs(data?.stats!);

    return data ? (
        <div className={styles.ingestionWidget}>
            {jobsToDisplay.map((sourceData) => (
                <div
                    className={styles.ingestionSourceRow}
                    key={sourceData.dataset_name + sourceData.started_at}
                >
                    <div className={styles.ingestionSourceIngestionBlock}>
                        <IngestionStatus
                            status={sourceData.status}
                            failed={sourceData.failed}
                            source={sourceFinder(sourceData.source_name)}
                        />
                        <div className={styles.elapsedTime}>
                            {getElapsedTimeForSource(
                                sourceData.started_at,
                                sourceData.updated_at,
                                sourceData.status
                            )}
                        </div>
                        {sourceData.status ===
                            DatasetIngestionStatus.COMPLETE && (
                            <div className={styles.elapsedTime}>
                                Started At:{" "}
                                {parseISO(
                                    sourceData.started_at
                                ).toLocaleString()}
                            </div>
                        )}
                    </div>
                    {sourceData.status !==
                        DatasetIngestionStatus.INITIALIZING &&
                        !sourceData.failed && (
                            <>
                            <div className={styles.ingestionSourceInfoBlock}>
                                <div className={styles.ingestPercent}>
                                    {sourceData.status !==
                                        DatasetIngestionStatus.COMPLETE && (
                                        <>
                                            {(
                                                (sourceData.rows_ingested /
                                                    sourceData.rows_to_ingest) *
                                                100
                                            ).toFixed(0)}
                                            {"% Complete"}
                                        </>
                                    )}
                                </div>
                            </div>
                            <div className={styles.ingestInfo}>
                            <IngestionDataBlock
                                icon={<RowsIngestedIcon />}
                                value={sourceData.rows_ingested}
                                label="Rows Ingested"
                            />
                            <IngestionDataBlock
                                icon={<RowsDroppedIcon />}
                                value={sourceData.rows_skipped}
                                label="Rows Skipped"
                            />
                            <IngestionDataBlock
                                icon={<RowsFilteredIcon />}
                                value={sourceData.rows_filtered}
                                label="Rows Filtered"
                            />
                            <IngestionDataBlock
                                icon={<RowsFailedIcon />}
                                value={sourceData.rows_failed}
                                label="Rows Failed"
                            />
                        </div>
                        </>
                        )}
                </div>
            ))}
            {jobsToDisplay.length > 0 && <div className={styles.separator} />}
            <div className={styles.ingestionLifetime}>
                Lifetime Rows
                <div className={styles.ingestionLifetimeRows}>
                    <div className={styles.ingesstionLifetimeRow}>
                        <div className={styles.ingestionLifetimeKey}>
                            <RowsIngestedIcon />
                            <div>Rows Ingested</div>
                        </div>
                        <div className={styles.ingestionLifetimeAbs}>
                            {nFormatter(data.lifetime_stats.rows_ingested, 3)}{" "}
                            total rows
                        </div>
                    </div>
                    <div className={styles.ingesstionLifetimeRow}>
                        <div className={styles.ingestionLifetimeKey}>
                            <RowsDroppedIcon />
                            <div>Rows Skipped</div>
                        </div>
                        <div className={styles.ingestionLifetimeAbs}>
                            {nFormatter(data.lifetime_stats.rows_skipped, 3)}{" "}
                            rows
                        </div>
                    </div>
                    <div className={styles.ingesstionLifetimeRow}>
                        <div className={styles.ingestionLifetimeKey}>
                            <RowsFilteredIcon />
                            <div>Rows Filtered</div>
                        </div>
                        <div className={styles.ingestionLifetimeAbs}>
                            {nFormatter(data.lifetime_stats.rows_filtered, 3)}{" "}
                            rows
                        </div>
                    </div>
                    <div className={styles.ingesstionLifetimeRow}>
                        <div className={styles.ingestionLifetimeKey}>
                            <RowsFailedIcon />
                            <div>Rows Failed</div>
                        </div>
                        <div className={styles.ingestionLifetimeAbs}>
                            {nFormatter(data.lifetime_stats.rows_failed, 3)}{" "}
                            rows
                        </div>
                    </div>
                    <LastUpdatedTimestamp
                        dataset={dataset}
                        branchName={branchName || ""}
                    />
                </div>
            </div>
        </div>
    ) : (
        <EmptyState
            loading={isLoading}
            spinnerSize="small"
            text={"There was a problem getting the latest ingestion runs"}
        />
    );
}

function IngestionDataBlock({
    icon,
    value,
    label,
}: {
    icon: JSX.Element;
    value: number;
    label: string;
}) {
    return (
        <Tooltip content={label}>
            <div className={styles.ingestionSourceInfoData}>
                {icon}
                <div>{nFormatter(value, 2)}</div>
            </div>
        </Tooltip>
    );
}

function IngestionStatus({
    status,
    source,
    failed = false,
}: {
    status: DatasetIngestionStatus;
    source: Source;
    failed?: boolean;
}) {
    const _status = failed ? DatasetIngestionStatus.FAILED : status;
    const SourceIcon = getSourceTypeIcon(source.source_type);
    return (
        <Tooltip content={`${source.source_type}-${source.table}`}>
            <div className={styles.ingestionStatus}>
                <div className={styles.sourceBox}>
                    <SourceIcon />
                </div>
                <ChevronRightIcon />
                <IngestionIcon status={_status} />
                {_status.toLowerCase()}
                {_status != DatasetIngestionStatus.COMPLETE && "..."}
            </div>
        </Tooltip>
    );
}

export function IngestionDataset({
    dataset,
    source,
    onClick,
}: {
    dataset: string;
    source?: Source;
    onClick?: () => void;
}) {
    const SourceIcon = getSourceTypeIcon(source?.source_type || "");
    return (
        <div className={styles.ingestionDataset} onClick={onClick}>
            <div className={styles.sourceBox}>
                <SourceIcon />
            </div>
            <ChevronRightIcon />
            <DatasetIcon /> {dataset.split("@")[0]}
        </div>
    );
}

export function IngestionIcon({ status }: { status: DatasetIngestionStatus }) {
    switch (status) {
        case DatasetIngestionStatus.INITIALIZING:
            return <div className={styles.pulseInitialize} />;
        case DatasetIngestionStatus.INGESTING:
            return <div className={styles.pulseIngest} />;
        case DatasetIngestionStatus.FAILED:
            return <XCircleIcon className={styles.statusFailed} />;
        case DatasetIngestionStatus.COMPLETE:
        default:
            return <CheckCircleIcon className={styles.statusComplete} />;
    }
}

export function BatchSinkIcon({ status }: { status: BatchSinkStatus }) {
    switch (status) {
        case BatchSinkStatus.COMPLETED:
            return <CheckCircleIcon className={styles.statusComplete} />;
        default:
            return <div className={styles.pulseIngest} />;
    }
}

export function ExtractHistoricalIcon({
    status,
}: {
    status: ExtractHistoricalJobStatus;
}) {
    switch (status) {
        case ExtractHistoricalJobStatus.Created:
            return <div className={styles.pulseInitialize} />;
        case ExtractHistoricalJobStatus.InProgress:
        case ExtractHistoricalJobStatus.Processing:
        case ExtractHistoricalJobStatus.Finalizing:
            return <div className={styles.pulseIngest} />;
        case ExtractHistoricalJobStatus.Failed:
        case ExtractHistoricalJobStatus.Cancelled:
            return <XCircleIcon className={styles.statusFailed} />;
        case ExtractHistoricalJobStatus.Complete:
        default:
            return <CheckCircleIcon className={styles.statusComplete} />;
    }
}

export default IngestionData;
