import VersionIcon from "icons/stack.svg";
import Metadata from "./Metadata";

const Version = ({ version }: { version: string }) => {
    return (
        <Metadata
        icon={<VersionIcon />}
        keyLabel="Version"
        keyValue={version}
        />
    );
};

export default Version;
