import classnames from "classnames";
import styles from "./styles/GraphLegends.module.scss";
import { StrokeStyle } from "./Graph";
import CalendarIcon from "icons/calendar.svg";

interface Props {
    lines: (LegendProps | "break")[];
    alignment?: "horizontal" | "vertical";
    isLoading?: boolean;
}

interface LineProp {
    style?: StrokeStyle;
    color: string;
    label: string;
    value?: string | number;
    id?: string;
    onClick?: (id: string) => void;
    onCmdClick?: (id: string) => void;
    disabled?: boolean;
}

const VerticalLoader = () => {
    return (
        <div className={styles.container}>
            {Array(8)
                .fill(0)
                .map((_, index) => (
                    <div
                        className={classnames(
                            styles.shimmerBG,
                            styles.contentLine
                        )}
                        key={index}
                    ></div>
                ))}
        </div>
    );
};

const EmptyState = () => {
    return (
        <div className={styles.emptyContainer}>
            <CalendarIcon width="1.5rem" height="1.5rem" />
            No data for the selected time range.
        </div>
    );
};

function GraphLegends({
    lines,
    alignment,
    isLoading = false,
}: Props): JSX.Element {
    if (isLoading && alignment === "vertical") {
        return <VerticalLoader />;
    }

    if (!lines.length && alignment === "vertical") {
        return <EmptyState />;
    }

    const isMutiSeries = lines.length > 1;
    return (
        <div
            className={
                alignment === "horizontal"
                    ? styles.horizontalContainer
                    : styles.container
            }
        >
            {lines.map((line, idx) => {
                if (line != "break") {
                    return (
                        <Legend
                            key={line.label}
                            {...line}
                            className={
                                isMutiSeries
                                    ? classnames(
                                          line.className,
                                          styles.multiLegends
                                      )
                                    : line.className
                            }
                        />
                    );
                } else {
                    return <Break key={idx} />;
                }
            })}
        </div>
    );
}

function Break(): JSX.Element {
    return <div className={styles.break} />;
}

interface LegendProps extends LineProp {
    className?: string;
}

export function Legend(line: LegendProps): JSX.Element {
    return (
        <div
            className={classnames(styles.legend, line.className, {
                [styles.visible]: !line.disabled,
                [styles.invisible]: line.disabled,
            })}
        >
            <div
                className={styles.colorAndLabel}
                onClick={(e) => {
                    if (e.metaKey) {
                        line.onCmdClick?.(line.id || line.label);
                    } else {
                        line.onClick?.(line.id || line.label);
                    }
                }}
            >
                {!line.style || line.style == "solid" ? (
                    <div
                        className={styles.colorBar}
                        style={{ background: line.color }}
                    />
                ) : (
                    <Dotted color={line.color} />
                )}
                <div className={styles.label}>{line.label}</div>
            </div>
            {line.value != undefined ? line.value : null}
        </div>
    );
}

export function Dotted({ color }: { color: string }): JSX.Element {
    return (
        <div className={styles.dotted}>
            <div className={styles.dot} style={{ background: color }} />
            <div className={styles.dot} style={{ background: color }} />
            <div className={styles.dot} style={{ background: color }} />
        </div>
    );
}

export function LineLegend(): JSX.Element {
    return <div className={styles.colorBarApex} />;
}

export default GraphLegends;
