// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".pKQEV5n4cplxgcfRfcLw{border:.125rem solid var(--glass);border-bottom-color:rgba(var(--glass), 0.2);border-radius:50%;display:inline-block;box-sizing:border-box;animation:um9RDk4XY1AKQhct15Wg 1s linear infinite}.ZHJaNWF7073FzywDi6uZ{width:1rem;height:1rem}.FoA71KB8m7tOhh_dnqkg{width:2rem;height:2rem}.crXtog9GP_gTho9uNqgJ{width:3rem;height:3rem;border-width:.25rem}@keyframes um9RDk4XY1AKQhct15Wg{0%{transform:rotate(0deg)}100%{transform:rotate(360deg)}}", "",{"version":3,"sources":["webpack://./src/console/shared/components/styles/Spinner.module.scss"],"names":[],"mappings":"AAEA,sBACI,iCAAA,CACA,2CAAA,CACA,iBAAA,CACA,oBAAA,CACA,qBAAA,CACA,iDAAA,CAGJ,sBACI,UAAA,CACA,WAAA,CAGJ,sBACI,UAAA,CACA,WAAA,CAGJ,sBACI,UAAA,CACA,WAAA,CACA,mBAAA,CAGJ,gCACI,GACI,sBAAA,CAGJ,KACI,wBAAA,CAAA","sourcesContent":["@use \"../../styles/common\" as *;\n\n.root {\n    border: 0.125rem solid $glass-color;\n    border-bottom-color: rgba($glass-color, 0.2);\n    border-radius: 50%;\n    display: inline-block;\n    box-sizing: border-box;\n    animation: rotation 1s linear infinite;\n}\n\n.small {\n    width: 1rem;\n    height: 1rem;\n}\n\n.medium {\n    width: 2rem;\n    height: 2rem;\n}\n\n.large {\n    width: 3rem;\n    height: 3rem;\n    border-width: .25rem;\n}\n\n@keyframes rotation {\n    0% {\n        transform: rotate(0deg);\n    }\n\n    100% {\n        transform: rotate(360deg);\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "pKQEV5n4cplxgcfRfcLw",
	"rotation": "um9RDk4XY1AKQhct15Wg",
	"small": "ZHJaNWF7073FzywDi6uZ",
	"medium": "FoA71KB8m7tOhh_dnqkg",
	"large": "crXtog9GP_gTho9uNqgJ"
};
export default ___CSS_LOADER_EXPORT___;
