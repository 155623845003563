import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  useReactTable,
  getSortedRowModel,
  SortingState,
  VisibilityState,
  ColumnSizingState,
  Table as TableType,
} from "@tanstack/react-table"

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "./Table"
import { useEffect, useState } from "react"
import { ColumnResizer } from "./DataTableColumnResizer"

export interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[]
  data: TData[]
  columnVisibilityState?: VisibilityState;
  onRowClick?: (row: TData) => void;
  onTableInit?: (table: TableType<TData>) => void;
  tableViewOptions?: (table: TableType<TData>) => React.ReactNode;
  fixedHeader?: boolean;
  windowed?: boolean;
}

export function DataTable<TData, TValue>({
  columns,
  data,
  onRowClick,
  onTableInit,
  tableViewOptions,
  fixedHeader,
  windowed,
  columnVisibilityState,
}: DataTableProps<TData, TValue>) {
  const [sorting, setSorting] = useState<SortingState>([])
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>(columnVisibilityState || {})
  const [colSizing, setColSizing] = useState<ColumnSizingState>({});

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onColumnSizingChange: setColSizing,
    state: {
      sorting,
      columnVisibility,
      columnSizing: colSizing
    },
    defaultColumn: {
      minSize: 60,
      maxSize: 900,
    },
    columnResizeMode: 'onChange',
  })

  useEffect(() => {
    onTableInit?.(table)
  }, [table])

  return (
    <>
      {tableViewOptions?.(table)}
      <div className="rounded-md overflow-x-auto min-h-full">
        <Table className={windowed ? "table-fixed" : "table-auto border-collapse"}>
          <TableHeader className={fixedHeader ? "sticky top-0 bg-card" : ""}>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead key={header.id}
                      className={`px-4 relative`}
                      style={{
                        width: header.getSize(),
                      }}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                      <ColumnResizer header={header} />
                    </TableHead>
                  )

                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody className="[&_tr]:border-accent">
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  key={row.id}
                  data-state={row.getIsSelected() && "selected"}
                  className={`${onRowClick ? "cursor-pointer" : ""} hover:bg-muted h-6`}
                  onClick={() => onRowClick?.(row.original)}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id} className="px-4 py-2">
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={columns.length} className="h-24 text-center text-muted-foreground">
                  No results.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
    </>
  )
}
