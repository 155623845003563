import styles from "./styles/UsageSettings.module.scss";
import { Overview } from "./Overview";
import { Metrics } from "./Metrics";
import { CoreOverview } from "./CoreOverview";
import { CoreMetrics } from "./CoreMetrics";
import UsageIcon from "icons/usage.svg";
import useSWR from "swr";
import { axiosFetcher } from "shared/utils/utils";
import EmptyState from "shared/components/EmptyState";

export function UsageSettings(): JSX.Element {
    const { data, isLoading } = useSWR(
        ["post", `/billing/core_enabled`],
        axiosFetcher
    );

    if (isLoading) {
        return <EmptyState loading={true} className={styles.fullWidth} />;
    }

    return (
        <div className={styles.gridContainer}>
            <div className={styles.flexContainer}>
                <div className={styles.titleRow}>
                    <div className={styles.title}>
                        <UsageIcon />
                        Usage
                    </div>
                </div>
                {data ? (
                    <>
                        <CoreOverview />
                        <CoreMetrics />
                    </>
                ) : (
                    <>
                        <Overview />
                        <Metrics />
                    </>
                )}
            </div>
        </div>
    );
}
