// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".rYcXSuUNy0q9mRHT90Kw{display:inline-flex;align-items:center;flex-wrap:wrap;gap:.25rem;min-width:0}.xIn3Kw5sULiItO3KogRm{display:flex;flex-direction:row-reverse;overflow:hidden;gap:.25rem}.EiFwV1NHjRpvw0FxLbLp{display:flex;gap:.25rem}.VmGYdALlTlBMoyDFZkyf{display:flex;justify-content:flex-end;margin-left:auto;gap:.25rem}", "",{"version":3,"sources":["webpack://./src/console/shared/components/expandableTags/TagRenderer.module.scss"],"names":[],"mappings":"AACA,sBACE,mBAAA,CACA,kBAAA,CACA,cAAA,CACA,UAAA,CACA,WAAA,CAGF,sBACE,YAAA,CACA,0BAAA,CACA,eAAA,CACA,UAAA,CAGF,sBACE,YAAA,CACA,UAAA,CAGF,sBACE,YAAA,CACA,wBAAA,CACA,gBAAA,CACA,UAAA","sourcesContent":["\n.container {\n  display: inline-flex;   \n  align-items: center;\n  flex-wrap: wrap;\n  gap: 0.25rem;\n  min-width: 0;\n}\n\n.expandableTagsLayout {\n  display: flex;\n  flex-direction: row-reverse;\n  overflow: hidden; \n  gap: 0.25rem;\n}\n\n.expandableTags {\n  display: flex;\n  gap: 0.25rem;\n}\n\n.tagContainer {\n  display: flex;\n  justify-content: flex-end;\n  margin-left: auto;\n  gap: 0.25rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "rYcXSuUNy0q9mRHT90Kw",
	"expandableTagsLayout": "xIn3Kw5sULiItO3KogRm",
	"expandableTags": "EiFwV1NHjRpvw0FxLbLp",
	"tagContainer": "VmGYdALlTlBMoyDFZkyf"
};
export default ___CSS_LOADER_EXPORT___;
