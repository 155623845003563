// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".XKeZMyUPmk9Wi9u87RTg{border:1px solid var(--border-heavy-color);border-radius:.75rem}.wOHrdwcM8caJkJyY9YXY{padding:1.5rem}.qSVY_SsywkARPgALcB26{border-top:1px solid var(--border-heavy-color);padding:1rem 1.5rem;display:flex;align-items:stretch}", "",{"version":3,"sources":["webpack://./src/console/features/settings/shared/SettingsCard/SettingsCard.module.scss"],"names":[],"mappings":"AAEA,sBACI,0CAAA,CACA,oBAAA,CAGJ,sBACI,cAAA,CAGJ,sBACI,8CAAA,CACA,mBAAA,CACA,YAAA,CACA,mBAAA","sourcesContent":["@use \"shared/styles/common\" as *;\n\n.root {\n    border: 1px solid $border-heavy-color;\n    border-radius: 0.75rem;\n}\n\n.wrapper {\n    padding: 1.5rem;\n}\n\n.footer {\n    border-top: 1px solid $border-heavy-color;\n    padding: 1rem 1.5rem;\n    display: flex;\n    align-items: stretch;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "XKeZMyUPmk9Wi9u87RTg",
	"wrapper": "wOHrdwcM8caJkJyY9YXY",
	"footer": "qSVY_SsywkARPgALcB26"
};
export default ___CSS_LOADER_EXPORT___;
