import { SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import {
    AlertDialogActions,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogTitle,
} from "shared/components/AlertDialog";
import { Field, TextInput } from "shared/components/TextInput";
import Button from "shared/components/Button";
import styles from "./styles/Dialog.module.scss";
import { useState } from "react";

export type DeleteFormValues = {
    confirm: string;
};

export const DeleteConfirmDialog = ({
    onSubmit,
    description = "This action cannot be undone",
    header = "Are you sure?",
    confirmStatement = "DELETE",
}: {
    onSubmit: SubmitHandler<DeleteFormValues>;
    description?: string;
    header?: string;
    confirmStatement?: string;
}) => {
    const schema = yup
        .object({
            confirm: yup.string().is([confirmStatement]).required(),
        })
        .required();

    const {
        register,
        reset,
        handleSubmit,
        formState: { isValid },
    } = useForm<DeleteFormValues>({
        resolver: yupResolver(schema),
    });

    const [submitting, setIsSubmitting] = useState(false);
    const onSubmitHandler = (data: DeleteFormValues) => {
        setIsSubmitting(true);
        onSubmit(data);
        setIsSubmitting(false);
        reset();
    };

    return (
        <AlertDialogContent>
            <AlertDialogTitle>{header}</AlertDialogTitle>
            <AlertDialogDescription>{description}</AlertDialogDescription>
            <form
                className={styles.delete_dialog_form}
                onSubmit={handleSubmit(onSubmitHandler)}
            >
                <Field label={`Type ${confirmStatement} to confirm`}>
                    <TextInput {...register("confirm")} />
                </Field>
                <AlertDialogActions>
                    <AlertDialogCancel>
                        <Button variant="outline">Cancel</Button>
                    </AlertDialogCancel>
                    <Button
                        disabled={!isValid}
                        loading={submitting}
                        type="submit"
                        color="danger"
                    >
                        Confirm
                    </Button>
                </AlertDialogActions>
            </form>
        </AlertDialogContent>
    );
};
