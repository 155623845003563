// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".nNAgUXV_OZapYD8X3Mdl{width:100%}.PIgPdQ8coc9V6XkmZoaB{width:100%;padding:8px;border:1px solid var(--primary-foreground);border-radius:8px;display:flex;gap:8px;justify-content:center;align-items:center;font-weight:600;font-size:14px;line-height:24px;color:var(--card-foreground);background:var(--card);cursor:pointer}", "",{"version":3,"sources":["webpack://./src/console/shared/components/styles/ThirdPartyAuth.module.scss"],"names":[],"mappings":"AAAA,sBACI,UAAA,CAGJ,sBACI,UAAA,CACA,WAAA,CAEA,0CAAA,CACA,iBAAA,CAEA,YAAA,CACA,OAAA,CACA,sBAAA,CACA,kBAAA,CAEA,eAAA,CACA,cAAA,CACA,gBAAA,CAEA,4BAAA,CACA,sBAAA,CACA,cAAA","sourcesContent":[".container {\n    width: 100%;\n}\n\n.button {\n    width: 100%;\n    padding: 8px;\n\n    border: 1px solid var(--primary-foreground);\n    border-radius: 8px;\n\n    display: flex;\n    gap: 8px;\n    justify-content: center;\n    align-items: center;\n\n    font-weight: 600;\n    font-size: 14px;\n    line-height: 24px;\n\n    color: var(--card-foreground);\n    background: var(--card);\n    cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "nNAgUXV_OZapYD8X3Mdl",
	"button": "PIgPdQ8coc9V6XkmZoaB"
};
export default ___CSS_LOADER_EXPORT___;
