// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AcLDONCbCeb3xYGxOTgq{display:flex;align-items:center;gap:.5rem;color:var(--muted-foreground-80)}.AcLDONCbCeb3xYGxOTgq svg{min-width:14px;min-height:14px}.wdKHCFdJqVf6jJGw1zlA{display:flex;flex-direction:column;gap:0}.wdKHCFdJqVf6jJGw1zlA .vC1OtMbFo81KNMtafH1g{font-size:.875rem;line-height:1rem;font-variation-settings:\"wght\" 500}.wdKHCFdJqVf6jJGw1zlA .Z4fS2tL4PIaD8570nHZK{font-size:.75rem;line-height:1rem;font-variation-settings:\"wght\" 400}", "",{"version":3,"sources":["webpack://./src/console/shared/components/styles/MetaField.module.scss"],"names":[],"mappings":"AAEA,sBACI,YAAA,CACA,kBAAA,CACA,SAAA,CACA,gCAAA,CAEA,0BACI,cAAA,CACA,eAAA,CAIR,sBACI,YAAA,CACA,qBAAA,CACA,KAAA,CACI,4CACI,iBAAA,CACA,gBAAA,CACA,kCAAA,CAGJ,4CACI,gBAAA,CACA,gBAAA,CACA,kCAAA","sourcesContent":["@use '../../styles/common' as *;\n\n.root {\n    display: flex;\n    align-items: center;\n    gap: 0.5rem;\n    color: var(--muted-foreground-80);\n\n    svg {\n        min-width: 14px;\n        min-height: 14px;\n    }\n}\n\n.content {\n    display: flex;\n    flex-direction: column;\n    gap: 0;\n        .name {\n            font-size: 0.875rem;\n            line-height: 1rem;\n            font-variation-settings: 'wght' 500;\n        }\n\n        .meta {\n            font-size: 0.75rem;\n            line-height: 1rem;\n            font-variation-settings: 'wght' 400;\n        }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "AcLDONCbCeb3xYGxOTgq",
	"content": "wdKHCFdJqVf6jJGw1zlA",
	"name": "vC1OtMbFo81KNMtafH1g",
	"meta": "Z4fS2tL4PIaD8570nHZK"
};
export default ___CSS_LOADER_EXPORT___;
