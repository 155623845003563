import classnames from "classnames";
import { useEffect, useState } from "react";
import { DateValue } from "./date-types";
import DateControl from "./DateControl";

import styles from "./styles/DateRangeControl.module.scss";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
    onDateUpdate: (fromDate: DateValue, toDate: DateValue) => void;
    initialFromDate: DateValue;
    initialToDate: DateValue;
    alignment: "left" | "right";
}

function DateRangeControl({
    onDateUpdate,
    initialFromDate,
    initialToDate,
    className,
    alignment,
    ...rest
}: Props): JSX.Element {
    const [fromDate, setFromDate] = useState<DateValue>(initialFromDate);
    const [toDate, setToDate] = useState<DateValue>(initialToDate);

    useEffect(() => {
        setFromDate(initialFromDate);
        setToDate(initialToDate);
    }, [initialFromDate, initialToDate]);

    return (
        <div {...rest} className={classnames(className, styles.container)}>
            <DateControl
                alignment={alignment}
                value={fromDate}
                onValueChange={(d) => {
                    setFromDate(d);
                    onDateUpdate(d, toDate);
                }}
            />
            <span className={styles.to}>to</span>
            <DateControl
                alignment={alignment}
                value={toDate}
                onValueChange={(d) => {
                    setToDate(d);
                    onDateUpdate(fromDate, d);
                }}
            />
        </div>
    );
}

export default DateRangeControl;
