import { HtmlLabel } from "@visx/annotation";
import styles from "./styles/AnnotationLabel.module.scss";
import { Dotted } from "./GraphLegends";
import { useCallback } from "react";

interface Props {
    x: number;
    y: number;
    graphWidth: number;
    label: string;
    stroke: string;
    strokeStyle?: string;
    labelValue?: string | number;
    xValue?: string | Date;
}

const CONTAINER_WIDTH = 300;
const DX = 15;

function AnnotationLabel(props: any): JSX.Element {
    const { series, seriesIndex, dataPointIndex, w, configs } = props;
    const yPoint = series[seriesIndex][dataPointIndex];
    const labelValue = !isNaN(yPoint as number)
        ? Math.round(Number(yPoint) * 100) / 100
        : yPoint;

    const serieName = w.globals.seriesNames[seriesIndex];
    const lineConfig = configs[serieName];
    const label = lineConfig.lineLabel || serieName;
    const strokeStyle = lineConfig.strokeStyle;
    const stroke = lineConfig.stroke;
    const timestamp = new Date(w.globals.seriesX[seriesIndex][dataPointIndex]);
    const formatLabelValue = useCallback(
        (label: string | number) => {
            const displayLabel =
                typeof label === "string" ? label : label.toFixed(2);
            return props.labelFormatter
                ? props.labelFormatter(displayLabel)
                : displayLabel;
        },
        [props.labelFormatter]
    );

    return (
        <div
            style={{
                width: CONTAINER_WIDTH,
                padding: "8px 0px",
                background: "#1A1A28",
                borderRadius: 8,
            }}
        >
            <div className={styles.labelValueX}>
                {timestamp.toLocaleString()}
            </div>
            <div className={styles.legendContainer}>
                <div className={styles.legend}>
                    <div className={styles.colorAndLabel}>
                        {strokeStyle && strokeStyle == "dotted" ? (
                            <Dotted color={stroke} />
                        ) : (
                            <div
                                className={styles.colorBar}
                                style={{ background: stroke }}
                            />
                        )}
                        <div className={styles.label}>{label}</div>
                    </div>
                    {labelValue != undefined ? (
                        <div className={styles.labelValue}>
                            {formatLabelValue(labelValue)}
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
    );
}

export default AnnotationLabel;
