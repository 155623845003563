import styles from "./styles/SearchBar.module.scss";
import SearchIcon from "icons/search.svg";
import { useEffect, useRef, useState } from "react";
import { Config, Filter } from "./filters";
import FilterBox from "./FilterBox";
import AddFilter from "./AddFilter";
import classNames from "classnames";
import IconButton from "../IconButton";

interface Props {
    config?: Config;
    onSearch: (text: string, filters: Filter[], force?: boolean) => void;
    fill?: boolean;
    defaultFilters?: Filter[];
    searchHide?: boolean;
    inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
    expandSearch?: boolean;
}

function SearchBar({
    config,
    onSearch,
    fill,
    defaultFilters,
    searchHide = false,
    expandSearch = false,
    inputProps,
}: Props): JSX.Element {
    const [filters, setFilters] = useState<Filter[]>(defaultFilters || []);
    const [text, setText] = useState("");
    const hiddenSearchRef = useRef<HTMLInputElement>(null);
    const [searchExpanded, setSearchExpand] = useState(true);
    const getKind = (key: string | number) => {
        return config?.filterConfigs[key].kind.kind;
    };
    const onClose = (idx: number) => {
        const newFilters = new Array(...filters);
        newFilters.splice(idx, 1);
        setFilters(newFilters);
        onSearch(text, newFilters, true);
    };
    const onFilterChange = (idx: number) => {
        return (filter: Filter) => {
            if (filter.value.length === 0) {
                onClose(idx);
                return;
            }
            const newFilters = new Array(...filters);
            newFilters.splice(idx, 1, filter);
            setFilters(newFilters);
            onSearch(text, newFilters);
        };
    };
    const onTextChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setText(e.target.value);
        onSearch(e.target.value, filters);
    };

    const onAddFilter = (filter: Filter) => {
        if (filter.value.length > 0) {
            const newFilters = [...filters, filter];
            setFilters(newFilters);
            onSearch(text, newFilters);
        }
    };

    const handleSearchClick = () => {
        setSearchExpand(!searchExpanded);
        hiddenSearchRef.current?.focus();
    };

    useEffect(() => {
        if (defaultFilters) {
            setFilters(defaultFilters);
        }
    }, [defaultFilters]);

    useEffect(() => {
        onSearch(text, filters);
    }, []);

    useEffect(() => {
        if (expandSearch) {
            hiddenSearchRef.current?.focus();
        }
    }, [expandSearch]);

    return (
        <div className={styles.container}>
            {!searchHide && !expandSearch ? (
                <div className={styles.inputBoxContainer}>
                    <div
                        className={classNames(
                            styles.inputBox,
                            fill && styles.inputBoxFill
                        )}
                    >
                        <div className={styles.searchIcon}>
                            <SearchIcon
                                width={16}
                                height={16}
                                viewBox="0 0 24 24"
                            />
                        </div>
                        <input
                            type="text"
                            spellCheck={false}
                            placeholder="Search"
                            value={text}
                            onChange={onTextChange}
                            className="bg-transparent"
                            {...inputProps}
                        />
                    </div>
                </div>
            ) : null}
            {expandSearch ? (
                <div>
                    <div
                        className={classNames(
                            styles.inputBox,
                            styles.expandSearch,
                            searchExpanded && styles.expandedBox
                        )}
                    >
                        <div
                            className={styles.searchIcon}
                            onClick={handleSearchClick}
                        >
                            <IconButton
                                icon={
                                    <SearchIcon
                                        width={16}
                                        height={16}
                                        viewBox="0 0 24 24"
                                    />
                                }
                                size="small"
                            />
                        </div>
                        <input
                            type="text"
                            // autoFocus={expandSearch}
                            spellCheck={false}
                            placeholder="Search"
                            value={text}
                            onChange={onTextChange}
                            ref={hiddenSearchRef}
                            className={
                                searchExpanded ? styles.animatedInputBox : ""
                            }
                            {...inputProps}
                        />
                    </div>
                </div>
            ) : null}
            {config ? (
                <div className={styles.filterRow}>
                    {filters.map(
                        (filter, idx) =>
                            config.filterConfigs[filter.key] && (
                                <FilterBox
                                    key={idx}
                                    filter={filter}
                                    filterConfig={
                                        config.filterConfigs[filter.key]
                                    }
                                    onClose={() => onClose(idx)}
                                    onFilterChange={onFilterChange(idx)}
                                    onSubmit={() => onSearch(text, filters)}
                                />
                            )
                    )}
                    {filters.length !==
                        Object.keys(config.filterConfigs).length && (
                        <AddFilter
                            config={config}
                            filters={filters}
                            onAdd={onAddFilter}
                        />
                    )}
                </div>
            ) : null}
        </div>
    );
}

export default SearchBar;
