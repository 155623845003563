import { MultiSelect } from "shared/components/MultiSelectInput";
import SlackIcon from "icons/slack.svg";
import FennelIcon from "icons/logo.svg";
import { axiosFetcher } from "shared/utils/utils";
import useSWR from "swr";
import { useCallback, useEffect, useState } from "react";
import styles from "./styles/ObservabilitySettings.module.scss";
import { Loader } from "shared/components/graph/Loader";
import DeleteIcon from "icons/delete.svg";
import IconButton from "shared/components/IconButton";
import { useCurrentUser } from "../../../context/CurrentUser";
import Button from "shared/components/Button";
import axios, { AxiosError } from "axios";
import { toast_json_error, toast_success } from "shared/utils/toast";
import {
    AlertDialog,
    AlertDialogActions,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogTitle,
} from "shared/components/AlertDialog";
import { AlertDialogDescription } from "@radix-ui/react-alert-dialog";
import { Field, TextInput } from "shared/components/TextInput";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";

type RuleFieldOption = { label: string; value: string };

interface SlackEntity {
    name: string;
    id: string;
}

interface SlackData {
    team_info: { icon: { image_44: string }; name: string };
    channels: SlackEntity[];
    current_channels: SlackEntity[];
    branches: SlackEntity[];
}

export const SlackDetails = () => {
    const { data, isLoading, error } = useSWR<SlackData>(
        ["post", "/slack/config"],
        axiosFetcher
    );

    const { branches } = useCurrentUser();
    const [currentChannels, setCurrentChannels] = useState<
        readonly RuleFieldOption[] | undefined
    >(undefined);
    const [activeBranches, setActiveBranches] = useState<
        readonly RuleFieldOption[] | undefined
    >(undefined);
    const [toDelete, setToDelete] = useState(false);
    const [isSaving, setIsSaving] = useState(false);

    useEffect(() => {
        setCurrentChannels(
            data?.current_channels.map((c) => ({ label: c.name, value: c.id }))
        );
    }, [data?.current_channels]);

    useEffect(() => {
        setActiveBranches(
            data?.branches.map((b) => ({ label: b.name, value: b.id }))
        );
    }, [data?.branches]);

    const onSave = useCallback(async () => {
        setIsSaving(true);
        const body = {
            branches: activeBranches?.map((b) => ({
                id: b.value,
                name: b.label,
            })),
            channels: currentChannels?.map((c) => ({
                id: c.value,
                name: c.label,
            })),
        };
        try {
            await axios.post("/slack/update-config", body);
            toast_success("Settings Saved");
        } catch (e) {
            toast_json_error(
                e as AxiosError<{ detail?: string }>,
                "Something went wrong"
            );
        } finally {
            setIsSaving(false);
        }
    }, [activeBranches, currentChannels]);

    if (isLoading) {
        return (
            <div className={styles.sectionInfo}>
                <Loader height={200} />
            </div>
        );
    }

    //Error means config was not found, move to default add to slack message
    if (error) {
        const urlState =
            window.location.protocol +
            "//" +
            window.location.host +
            "/slack/auth";
        return (
            <>
                <div className={styles.sectionInfo}>
                    Follow the on-screen steps to configure your Slack
                    Integration
                </div>
                <div className={styles.contactSection}>
                    <a
                        href={`https://slack.com/oauth/v2/authorize?client_id=2484652913216.7493442389699&scope=channels:join,chat:write,team:read&user_scope=&state=${urlState}`}
                    >
                        <img
                            alt="Add to Slack"
                            height="40"
                            width="139"
                            src="https://platform.slack-edge.com/img/add_to_slack.png"
                            srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x"
                        />
                    </a>
                </div>
            </>
        );
    }

    return (
        <>
            <div className={styles.sectionInfo}>
                Follow the on-screen steps to configure your Slack Integration
            </div>
            <div className={styles.steps}>
                <div className={styles.step}>
                    <div className={styles.step_title}>
                        <img src={data?.team_info.icon["image_44"]} />
                        {data?.team_info.name}
                        <IconButton
                            icon={<DeleteIcon />}
                            onClick={() => setToDelete(true)}
                            size="small"
                        />
                    </div>
                </div>
                <div className={styles.step}>
                    <div className={styles.step_title}>
                        <SlackIcon />
                        Channels to notify on
                    </div>
                    <MultiSelect
                        isMulti
                        onChange={(v) => setCurrentChannels(v)}
                        noOptionsMessage={({ inputValue }) =>
                            inputValue
                                ? `No actions matching "${inputValue}"`
                                : "All channels selected."
                        }
                        value={currentChannels}
                        placeholder="Enter the slack channels where you would like to get notified"
                        options={data?.channels.map((c) => ({
                            label: c.name,
                            value: c.id,
                        }))}
                    />
                </div>

                <div className={styles.step}>
                    <div className={styles.step_title}>
                        <FennelIcon />
                        Branches to notify on
                    </div>
                    <MultiSelect
                        isMulti
                        onChange={(v) => {
                            if (v.some((option) => option.value === "all")) {
                                // If "All branches" is selected, override the selection with only "All branches"
                                setActiveBranches([
                                    { label: "All branches", value: "all" },
                                ]);
                            } else {
                                setActiveBranches(v);
                            }
                        }}
                        noOptionsMessage={({ inputValue }) =>
                            inputValue
                                ? `No actions matching "${inputValue}"`
                                : "All branches selected."
                        }
                        value={activeBranches}
                        placeholder="Enter the branches on which you would like to get notified"
                        options={[
                            { label: "All branches", value: "all" },
                            ...(branches?.map((b) => ({
                                label: b.name,
                                value: b.id,
                            })) || []),
                        ]}
                    />
                </div>

                <AlertDialog
                    open={toDelete}
                    onOpenChange={(open) => setToDelete(open)}
                >
                    <SlackDeleteDialog
                        onSuccess={() => {
                            setToDelete(false);
                        }}
                    />
                </AlertDialog>

                <div>
                    <Button
                        size="small"
                        color="primary"
                        type="submit"
                        onClick={onSave}
                        loading={isSaving}
                    >
                        Save
                    </Button>
                </div>
            </div>
        </>
    );
};

type SlackDeleteFormValues = {
    confirm: string;
};

const SlackDeleteDialog = ({ onSuccess }: { onSuccess: () => void }) => {
    const schema = yup
        .object({
            confirm: yup.string().is(["DELETE"]).required(),
        })
        .required();

    const [submitting, setIsSubmitting] = useState<boolean>(false);
    const {
        register,
        reset,
        handleSubmit,
        formState: { isValid },
    } = useForm<SlackDeleteFormValues>({
        resolver: yupResolver(schema),
    });

    const onSubmit = useCallback(async () => {
        try {
            setIsSubmitting(true);
            await await axios.delete("/slack/");
            setIsSubmitting(false);
            toast_success("Slack app uninstalled and configuration deleted.");
            onSuccess();
            reset();
        } catch (e) {
            setIsSubmitting(false);
            toast_json_error(
                e as AxiosError<{ detail?: string }>,
                "Something went wrong"
            );
            reset();
        }
    }, []);

    return (
        <AlertDialogContent>
            <AlertDialogTitle>Uninstall Fennel Integration</AlertDialogTitle>
            <AlertDialogDescription>
                This will uninstall Fennel Integration from your Slack Workspace
                and delete all saved preferences.
            </AlertDialogDescription>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Field label={`Type "DELETE" to confirm`}>
                    <TextInput {...register("confirm")} />
                </Field>
                <AlertDialogActions>
                    <AlertDialogCancel>
                        <Button variant="outline">Cancel</Button>
                    </AlertDialogCancel>
                    <Button
                        disabled={!isValid}
                        loading={submitting}
                        type="submit"
                        color="danger"
                    >
                        Confirm
                    </Button>
                </AlertDialogActions>
            </form>
        </AlertDialogContent>
    );
};
