import {
    Children,
    ForwardedRef,
    InputHTMLAttributes,
    PropsWithChildren,
    ReactElement,
    cloneElement,
    forwardRef,
} from "react";
import classnames from "classnames";
import { ControllerFieldState } from "react-hook-form";
import * as LabelPrimitive from "@radix-ui/react-label";

import styles from "./styles/TextInput.module.scss";

interface InputProps<T> extends InputHTMLAttributes<T> {
    disabled?: boolean;
    value?: string;
    type?: "text" | "number" | "password" | "datetime-local" | "textarea";
}

export const TextInput = forwardRef(
    (
        {
            className,
            ...props
        }: InputProps<HTMLInputElement | HTMLTextAreaElement>,
        ref: ForwardedRef<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        return props.type === "textarea" ? (
            <textarea
                ref={ref as ForwardedRef<HTMLTextAreaElement>}
                {...props}
                className={classnames(styles.input, "bg-transparent", className)}
            />
        ) : (
            <input
                ref={ref as ForwardedRef<HTMLInputElement>}
                {...props}
                className={classnames(styles.input, "bg-transparent", className)}
            />
        );
    }
);
TextInput.displayName = "TextInput";

export const Label = ({
    className,
    children,
    ...props
}: LabelPrimitive.LabelProps & React.RefAttributes<HTMLLabelElement>) => (
    <LabelPrimitive.Root
        {...props}
        className={classnames(styles.label, className)}
    >
        {children}
    </LabelPrimitive.Root>
);

export const Helper = ({
    children,
    className,
    type = "neutral",
}: PropsWithChildren<{
    className?: string;
    type?: "neutral" | "info" | "error";
}>) => (
    <div className={classnames(styles.helper, styles[type], className)}>
        {children}
    </div>
);

export const Field = ({
    children,
    helper,
    label,
    state,
    className,
}: PropsWithChildren<{
    helper?: string;
    label?: string;
    state?: ControllerFieldState;
    className?: string;
}>) => {
    return (
        <fieldset className={classnames(styles.field, [className])}>
            {label ? <Label>{label}</Label> : null}
            {cloneElement(Children.only(children) as ReactElement)}
            <Helper type={state?.error ? "error" : "neutral"}>
                {state?.error ? state.error.message : helper}
            </Helper>
        </fieldset>
    );
};
