import { useCallback, useState } from "react";
import axios, { AxiosError } from "axios";
import { mutate } from "swr";
import { toast_json_error, toast_success } from "shared/utils/toast";
import { DeleteConfirmDialog } from "shared/components/DeleteConfirmDialog";

const RoleDeleteDialog = ({
    onSuccess,
    roleId,
}: {
    onSuccess: () => void;
    roleId?: number;
}) => {
    const onSubmit = useCallback(async () => {
        try {
            await axios.delete(`/api/v1/auth/role/${roleId}`, {
                headers: {
                    "Content-Type": "application/json",
                },
            });
            mutate(["get", "/api/v1/auth/role"]);
            toast_success("Role deleted.");
            onSuccess();
        } catch (e) {
            toast_json_error(
                e as AxiosError<{ detail?: string }>,
                "Something went wrong"
            );
        }
    }, [onSuccess, roleId]);

    return <DeleteConfirmDialog onSubmit={onSubmit} />;
};

export default RoleDeleteDialog;
