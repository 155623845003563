import { getSourceTypeIcon } from "../../utils/utils";
import DatasetIcon from "icons/dataset.svg";
import ForkIcon from "icons/fork.svg";
import KeyIcon from "icons/key-field.svg";
import CodeIcon from "icons/code.svg";
import ClockIcon from "icons/clock.svg";
import { CreateNodeOpts, DAGNodeData } from "./types";
import styles from "./Node.module.scss";
import { Edge, Node } from "reactflow";

export const getColorset = (type: string) => {
    switch (type) {
        case "featureset":
            return {
                primary: "#2E8D76",
                accent: "#A5EEDB",
            };
        case "dataset":
            return {
                primary: "#453AA6",
                accent: "#BCB8F4",
            };
        default:
            return {
                primary: "#000",
                accent: "#A7BBFB",
            };
    }
};

export const getExpandLimit = (type: string) => {
    switch (type) {
        case "featureset":
            return 10;
        case "dataset":
            return 0;
        default:
            return undefined;
    }
};

export const getHandleStyle = (accent: string) => ({
    width: "10px",
    height: "10px",
    margin: "auto",
    background: accent,
    borderRadius: "15px",
    border: `2px solid ${accent}`,
    right: "-0.25rem",
});

export const getChildIcon = (icon?: string) => {
    if (icon === "Timestamp") {
        return <ClockIcon key={icon} />;
    } else if (icon === "Key") {
        return <KeyIcon key={icon} />;
    }
};

export const getSuffixElement = (
    type: string,
    sublabel?: { prefix: string; suffix: string }
) => {
    switch (type) {
        case "dataset":
            if (sublabel?.prefix === "Derived from") {
                return (
                    <span className={styles.suffixTab}>
                        <ForkIcon /> {sublabel.suffix}
                    </span>
                );
            } else {
                const IconComponent = getSourceTypeIcon(
                    sublabel?.suffix as string
                );
                return (
                    <span className={styles.suffixTab}>
                        <IconComponent /> {sublabel?.suffix}
                    </span>
                );
            }
        case "featureset":
            return (
                <span color={getColorset(type).primary}>
                    {sublabel?.suffix}
                </span>
            );
    }
};

export const getTargetIcon = (type: string, data?: DAGNodeData) => {
    switch (type) {
        case "source":
            return getSourceTypeIcon(data?.sublabel?.prefix || "");
        case "dataset":
            if (data?.sublabel?.prefix === "Derived from") {
                return ForkIcon;
            } else {
                return DatasetIcon;
            }
        default:
            return CodeIcon;
    }
};

export const targetHandleStyles = (accent: string) => ({
    display: "flex",
    width: "1.5rem",
    height: "1.5rem",
    justifyContent: "center",
    alignItems: "center",
    gap: "0.5rem",
    left: "-0.75rem",
    top: "1.75rem",
    borderRadius: "3rem",
    border: `0.5px solid ${accent}`,
    background: " #FFF",
});

export function createNode({
    name,
    type,
    path,
    selected,
    root,
    children,
    isExpandable,
    sublabel,
    partitionIndex,
}: CreateNodeOpts): Node<DAGNodeData> {
    return {
        id: name,
        data: {
            label: name,
            path,
            root,
            children,
            isExpandable,
            sublabel,
            layoutOptions: {
                "partitioning.partition": partitionIndex || 0,
            },
        },
        selected,
        type,
        position: {
            x: 0,
            y: 0,
        },
    };
}

type CreateEdgeOpts = {
    source: string;
    target: string;
    sourceHandle?: string;
    targetHandle?: string;
};

export function createEdge({
    source,
    target,
    sourceHandle,
    targetHandle,
}: CreateEdgeOpts): Edge {
    const id = [source, sourceHandle, target, targetHandle]
        .filter((part) => !!part)
        .join(".");
    return {
        id,
        source,
        target,
        sourceHandle,
        type: "custom",
        focusable: false,
    };
}

export const findConnectedAncestors = (
    startNodeId: string | undefined,
    edges: Edge[],
    nodes: Node[]
): { nodes: Node[]; edges: Edge[] } => {
    const queue = [startNodeId];
    const visitedNodes: Node[] = [];
    const visitedEdges: Edge[] = [];

    if (!startNodeId) {
        return { nodes: visitedNodes, edges: visitedEdges };
    }

    while (queue.length) {
        const nodeId = queue.shift();

        // Look for nodes that point to this one (ancestors)
        const incomingEdges = edges.filter((edge) => edge.target === nodeId);
        incomingEdges.forEach((edge) => {
            const sourceNode = nodes.find((node) => node.id === edge.source);
            if (sourceNode && !visitedNodes.includes(sourceNode)) {
                visitedNodes.push(sourceNode);
                queue.push(sourceNode.id);
            }
            visitedEdges.push(edge);
        });
    }

    const currentNode = nodes.find((node) => node.id === startNodeId);
    visitedNodes.push(currentNode!);

    return { nodes: visitedNodes, edges: visitedEdges };
};

export const findConnectedDescendants = (
    startNodeId: string | undefined,
    edges: Edge[],
    nodes: Node[],
    startSourceHandle?: string
): { nodes: Node[]; edges: Edge[] } => {
    const queue = [startNodeId];
    const visitedNodes: Node[] = [];
    const visitedEdges: Edge[] = [];

    if (!startNodeId) {
        return { nodes: visitedNodes, edges: visitedEdges };
    }

    if (queue.length) {
        const nodeId = queue.shift();

        // Look for nodes that this one points to (descendants)
        const outgoingEdges = edges.filter(
            (edge) =>
                edge.source === nodeId &&
                (startSourceHandle
                    ? edge.sourceHandle === startSourceHandle
                    : true)
        );
        outgoingEdges.forEach((edge) => {
            const targetNode = nodes.find((node) => node.id === edge.target);
            if (targetNode && !visitedNodes.includes(targetNode)) {
                visitedNodes.push(targetNode);
                queue.push(targetNode.id);
            }
            visitedEdges.push(edge);
        });
    }

    while (queue.length) {
        const nodeId = queue.shift();

        // Look for nodes that this one points to (descendants)
        const outgoingEdges = edges.filter((edge) => edge.source === nodeId);
        outgoingEdges.forEach((edge) => {
            const targetNode = nodes.find((node) => node.id === edge.target);
            if (targetNode && !visitedNodes.includes(targetNode)) {
                visitedNodes.push(targetNode);
                queue.push(targetNode.id);
            }
            visitedEdges.push(edge);
        });
    }

    return { nodes: visitedNodes, edges: visitedEdges };
};
