import ErrorIcon from "icons/warning.svg";
import DatasetIcon from "icons/dataset.svg";
import CommitIcon from "icons/commits.svg";
import CodeIcon from "icons/code.svg";
import MetricsIcon from "icons/metrics.svg";
import FeatureIcon from "icons/feature.svg";
import SourcesIcon from "icons/source.svg";
import LineageIcon from "icons/fork.svg";
import BranchIcon from "icons/branch.svg";
import UsageIcon from "icons/usage.svg";
import MembersIcon from "icons/members.svg";
import RolesIcon from "icons/shield.svg";
import SSOIcon from "icons/owner.svg";
import RadarIcon from "icons/radar.svg";
import CubeIcon from "icons/cube.svg";
import SettingsIcon from "icons/settings.svg";
import TokenIcon from "icons/token.svg";
import IngestionIcon from "icons/ingestion.svg";
import { branchedHomeLink } from "../utils/utils";

export const DASHBOARD_NAV = {
    title: "Metrics",
    link: (branchName?: string) => branchedHomeLink(branchName, "metrics"),
    icon: <MetricsIcon width={"1rem"} height={"1rem"} />,
};

export const DATASET_NAV = {
    title: "Datasets",
    link: (branchName?: string) => branchedHomeLink(branchName, "datasets"),
    icon: <DatasetIcon width={"1rem"} height={"1rem"} />,
};

export const INGESTION_NAV = {
    title: "Ingestion",
    link: (branchName?: string) => branchedHomeLink(branchName, "ingestion"),
    icon: <IngestionIcon width={"1rem"} height={"1rem"} />,
};

export const BATCH_SINK_RUNS_NAV = {
    title: "Sink",
    link: (branchName?: string) => branchedHomeLink(branchName, "sink_runs"),
    icon: <CubeIcon width={"1rem"} height={"1rem"} />,
};

export const FEATURES_NAV = {
    title: "Features",
    link: (branchName?: string) => branchedHomeLink(branchName, "features"),
    icon: <FeatureIcon width={"1rem"} height={"1rem"} />,
};

export const ENTITIES_NAV = {
    title: "Entities",
    link: (branchName?: string) => branchedHomeLink(branchName, "entities"),
    icon: <FeatureIcon width={"1rem"} height={"1rem"} />,
};

export const LINEAGE_NAV = {
    title: "Lineage",
    link: (branchName?: string) => branchedHomeLink(branchName, "lineage"),
    icon: <LineageIcon width={"1rem"} height={"1rem"} />,
};

export const SOURCES_NAV = {
    title: "Sources",
    icon: <SourcesIcon width={"1rem"} height={"1rem"} />,
};

export const ERRORS_NAV = {
    title: "Errors",
    link: (branchName?: string) => branchedHomeLink(branchName, "errors"),
    icon: <ErrorIcon width={"1rem"} height={"1rem"} />,
};

export const QUERIES_NAV = {
    title: "Queries",
    link: (branchName?: string) => branchedHomeLink(branchName, "queries"),
    icon: <CodeIcon width={"1rem"} height={"1rem"} />,
};

export const COMMITS_NAV = {
    title: "Commits",
    link: (branchName?: string) => branchedHomeLink(branchName, "commits"),
    icon: <CommitIcon width={"1rem"} height={"1rem"} />,
};

export const DATA_NAV = {
    title: "Data",
    link: (branchName?: string) => branchedHomeLink(branchName, "data"),
    icon: <DatasetIcon width={"1rem"} height={"1rem"} />,
};

export const settingTabsParams = [
    { title: "Branches", link: "/branches/", icon: <BranchIcon /> },
    { title: "Usage", link: "/usage/", icon: <UsageIcon /> },
    { title: "Sources", link: "/sources", icon: <SourcesIcon /> },
];

export const MEMBERS_NAV = {
    title: "Members",
    link: "/settings/organization/members",
    icon: <MembersIcon width={"1rem"} height={"1rem"} />,
};

export const ROLES_NAV = {
    title: "Roles",
    link: "/settings/organization/roles",
    icon: <RolesIcon width={"1rem"} height={"1rem"} />,
};

export const SSO_NAV = {
    title: "SSO",
    link: "/settings/organization/sso",
    icon: <SSOIcon width={"1rem"} height={"1rem"} />,
};

export const OBSERVABILITY_NAV = {
    title: "Observability",
    link: "/settings/organization/observability",
    icon: <RadarIcon width={"1rem"} height={"1rem"} />,
};

export const SERVICE_NAV = {
    title: "Service Accounts",
    link: "/settings/organization/service-accounts",
    icon: <CubeIcon width={"1rem"} height={"1rem"} />,
};

export const GENERAL_SETTINGS_NAV = {
    title: "General",
    link: "/settings/general",
    icon: <SettingsIcon width={"1rem"} height={"1rem"} />,
};

export const TOKEN_NAV = {
    title: "Tokens",
    link: "/settings/tokens",
    icon: <TokenIcon width={"1rem"} height={"1rem"} />,
};

export const tabParams = [
    "DEVELOP",
    COMMITS_NAV,
    QUERIES_NAV,
    "MONITOR",
    DASHBOARD_NAV,
    INGESTION_NAV,
    BATCH_SINK_RUNS_NAV,
    ERRORS_NAV,
    "EXPLORE",
    ENTITIES_NAV,
    LINEAGE_NAV,
    DATA_NAV,
];
