import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import MenuItem from "../../components/MenuItem";
import styles from "./RefreshInterval.module.scss";
import ReloadIcon from "icons/reload.svg";
import IconButton from "../IconButton";
import Button from "../Button";

interface Props {
    current: number;
    onSelectionChange: (selectedValues: number) => void;
}

const intervals = [
    {
        value: 0,
        label: "Off",
    },
    {
        value: 5000,
        label: "5s",
    },
    {
        value: 10000,
        label: "10s",
    },
    {
        value: 30000,
        label: "30s",
    },
    {
        value: 60000,
        label: "1m",
    },
    {
        value: 300000,
        label: "5m",
    },
    {
        value: 900000,
        label: "15m",
    },
    {
        value: 1800000,
        label: "30m",
    },
    {
        value: 3600000,
        label: "1h",
    },
    {
        value: 7200000,
        label: "2h",
    },
];

export default function RefreshInterval({ onSelectionChange, current }: Props) {
    const handleItemClick = (value: number) => {
        onSelectionChange(value);
    };

    const currentLabel = intervals.find((i) => i.value == current)?.label;

    return (
        <DropdownMenu.Root>
            <DropdownMenu.Trigger asChild>
                <Button icon={<ReloadIcon />} size="small" variant="outline">
                    {currentLabel || "Off"}
                </Button>
            </DropdownMenu.Trigger>
            <DropdownMenu.Portal>
                <DropdownMenu.Content className={styles.content}>
                    <div className={styles.items}>
                        {intervals.map((item, idx) => {
                            return (
                                <DropdownMenu.Item
                                    onClick={() => handleItemClick(item.value)}
                                    key={idx}
                                    className={styles.menuItemWrapper}
                                >
                                    <MenuItem active={item.value == current}>
                                        <div className={styles.menuItem}>
                                            {item.label}
                                        </div>
                                    </MenuItem>
                                </DropdownMenu.Item>
                            );
                        })}
                    </div>
                </DropdownMenu.Content>
            </DropdownMenu.Portal>
        </DropdownMenu.Root>
    );
}
