import OwnerIcon from "icons/owner.svg";
import Metadata from "./Metadata";

const Owner = ({ owner }: { owner: string }) => {
    return (
        <Metadata
        keyLabel="Owner"
        icon={<OwnerIcon/>}
        keyValue={owner}
        />
    );
};

export default Owner;
