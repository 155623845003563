import { toISOString } from "shared/components/date-picker/date-types";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import styles from "./DashboardPage.module.scss";
import axios from "axios";
import { branchedFeatureLink, branchedMetricsLink } from "shared/utils/utils";
import {
    avgY,
    DataPoint,
    getMinMax,
    parsePromResponse,
} from "shared/utils/prometheus";
import {
    DEFAULT_STROKE,
    FULL_GRAPH_HEIGHT,
    FULL_GRAPH_WIDTH,
    StrokeStyle,
} from "shared/components/graph/Graph";
import NumberSeries from "shared/components/graph/NumberSeries";
import InteractiveGraph from "shared/components/graph/InteractiveGraph";
import useSWR from "swr";

export interface FeatureGraphProps {
    startDate: Date;
    endDate: Date;
    interval?: number;
    featureName?: string | null;
    featuresetName?: string | null;
    onDragChange?: (chartContext: any, options: any) => void;
}

export interface ExtractorGraphProps {
    startDate: Date;
    endDate: Date;
    interval?: number;
    extractorName?: string | null;
    onDragChange?: (chartContext: any, options: any) => void;
}

interface LatencyData {
    p50: DataPoint[];
    p75: DataPoint[];
    p90: DataPoint[];
    p99: DataPoint[];
    avgP50: number;
    avgP75: number;
    avgP90: number;
    avgP99: number;
}

const fetchExtractorLatency = async (
    url: string,
    extractorName: string,
    startDate: Date,
    endDate: Date
) => {
    const response = await axios.post(url, {
        extractor_name: extractorName,
        start: toISOString(startDate),
        end: toISOString(endDate),
    });

    const p50 = parsePromResponse(response.data.p50, 1000);
    const p75 = parsePromResponse(response.data.p75, 1000);
    const p90 = parsePromResponse(response.data.p90, 1000);
    const p99 = parsePromResponse(response.data.p99, 1000);
    return {
        p50,
        p75,
        p90,
        p99,
        avgP50: avgY(p50),
        avgP75: avgY(p75),
        avgP90: avgY(p90),
        avgP99: avgY(p99),
    };
};

export function ExtractorLatency({
    startDate,
    endDate,
    extractorName,
    interval,
    onDragChange,
}: ExtractorGraphProps) {
    const { branchName } = useParams();
    const { data, error, isLoading, isValidating } = useSWR(
        ["extractor_latency", branchName, extractorName, startDate, endDate],
        ([, branchName, extractorName, startDate, endDate]) =>
            fetchExtractorLatency(
                branchedMetricsLink(branchName, "extractor_latency"),
                extractorName as string,
                startDate,
                endDate
            ),
        {
            refreshInterval: interval,
            revalidateOnFocus: false,
            revalidateOnReconnect: false,
            keepPreviousData: true,
        }
    );

    const configs = {
        p50: {
            stroke: "#2C2D3A",
            lineLabel: "p50",
            strokeStyle: "dotted" as StrokeStyle,
        },
        p75: {
            stroke: "#686779",
            lineLabel: "p75",
            strokeStyle: "dotted" as StrokeStyle,
        },
        p90: {
            stroke: "#C2C0D3",
            lineLabel: "p90",
            strokeStyle: "dotted" as StrokeStyle,
        },
        p99: {
            stroke: "#0D0B19",
            lineLabel: "p99",
            strokeStyle: "dotted" as StrokeStyle,
        },
    };

    return (
        <div className={styles.graphContainer}>
            <div className={styles.graphHeader}>
                <div className={styles.graphTitle}>Latency</div>
                <div className={styles.graphSubtitle}>
                    Latency b/w extractor execute is called and time taken by
                    the actual execution
                </div>
                <InteractiveGraph
                    startDate={startDate}
                    endDate={endDate}
                    data={
                        data && {
                            p50: data.p50,
                            p75: data.p75,
                            p90: data.p90,
                            p99: data.p99,
                        }
                    }
                    isLoaded={!isLoading}
                    configs={configs}
                    onDragChange={onDragChange}
                />
            </div>
        </div>
    );
}

const fetchFeatureData = async (
    url: string,
    featuresetName: string,
    featureName: string,
    startDate: Date,
    endDate: Date
) => {
    const response = await axios.post(url, {
        featureset: featuresetName,
        feature: featureName,
        start: toISOString(startDate),
        end: toISOString(endDate),
    });

    return parsePromResponse(response.data);
};

export function FeatureActivity({
    startDate,
    endDate,
    featureName,
    featuresetName,
    interval,
    onDragChange,
}: FeatureGraphProps): JSX.Element {
    const { branchName } = useParams();
    const { data, error, isLoading } = useSWR(
        [
            "feature",
            branchName,
            featuresetName,
            featureName,
            startDate,
            endDate,
        ],
        ([, branchName, featuresetName, featureName, startDate, endDate]) =>
            fetchFeatureData(
                branchedMetricsLink(branchName, "feature"),
                featuresetName as string,
                featureName as string,
                startDate,
                endDate
            ),
        {
            refreshInterval: interval,
            revalidateOnFocus: false,
            revalidateOnReconnect: false,
            keepPreviousData: true,
        }
    );
    return (
        <div className={styles.graphContainer}>
            <div className={styles.graphHeader}>
                <div className={styles.graphTitle}>Activity</div>
                <div className={styles.graphSubtitle}>
                    Rate of query on features over time
                </div>
            </div>
            <div className={styles.graph}>
                <NumberSeries
                    startDate={startDate}
                    endDate={endDate}
                    height={FULL_GRAPH_HEIGHT}
                    width={FULL_GRAPH_WIDTH}
                    lineLabel="Extracted"
                    stroke={DEFAULT_STROKE}
                    data={data}
                    isLoaded={!isLoading}
                    onDragChange={onDragChange}
                />
            </div>
        </div>
    );
}

interface DistData {
    mean: DataPoint[];
    lower: DataPoint[];
    upper: DataPoint[];
}

const fetchDistData = async (
    url: string,
    featuresetName: string,
    featureName: string,
    startDate: Date,
    endDate: Date
) => {
    const response = await axios.post(url, {
        featureset: featuresetName,
        feature: featureName,
        start: toISOString(startDate),
        end: toISOString(endDate),
    });

    return {
        mean: parsePromResponse(response.data.mean),
        lower: parsePromResponse(response.data.lower),
        upper: parsePromResponse(response.data.upper),
    };
};

export function DistributionSection({
    startDate,
    endDate,
    featuresetName,
    featureName,
    interval,
    onDragChange,
}: FeatureGraphProps): JSX.Element {
    const { branchName } = useParams();
    const {
        data: distData,
        error,
        isLoading,
    } = useSWR(
        [
            "distributions",
            branchName,
            featuresetName,
            featureName,
            startDate,
            endDate,
        ],
        ([, branchName, featuresetName, featureName, startDate, endDate]) => {
            const featureLink = branchedFeatureLink(
                branchName,
                featuresetName || "",
                featureName || ""
            );
            const link = `${featureLink}/distributions`;
            return fetchDistData(
                link,
                featuresetName as string,
                featureName as string,
                startDate,
                endDate
            );
        },
        {
            refreshInterval: interval,
            revalidateOnFocus: false,
            revalidateOnReconnect: false,
            keepPreviousData: true,
        }
    );

    const lMinMax = useMemo(() => getMinMax(distData?.lower || []), [distData]);
    const uMinMax = useMemo(() => getMinMax(distData?.upper || []), [distData]);
    const minMax = lMinMax && uMinMax && { min: lMinMax.min, max: uMinMax.max };

    return (
        <div className={styles.graphContainer}>
            <div className={styles.graphHeader}>
                <div className={styles.graphTitle}>Feature Distribution</div>
                <div className={styles.graphSubtitle}>
                    Changes in the probability distribution of a feature
                </div>
                <InteractiveGraph
                    startDate={startDate}
                    endDate={endDate}
                    data={{ ...distData }}
                    yDomain={minMax}
                    isLoaded={!isLoading}
                    configs={{
                        mean: {
                            stroke: "#655ce9",
                            lineLabel: "Mean",
                        },
                        lower: {
                            stroke: "#261584",
                            lineLabel: "-σ",
                            strokeStyle: "dotted",
                        },
                        upper: {
                            stroke: "#261584",
                            lineLabel: "+σ",
                            strokeStyle: "dotted",
                        },
                    }}
                    onDragChange={onDragChange}
                    showLegend
                />
            </div>
        </div>
    );
}
