import * as React from "react";
import * as PopoverPrimitive from "@radix-ui/react-popover";
import classNames from "classnames";
import styles from "./SortingBox.mod.scss";
import DropdownIcon from "icons/chevron-selector-vertical.svg";
import SortingIcon from "icons/sorting.svg";
import FilterAscIcon from "icons/filter-asc.svg";
import FilterDescIcon from "icons/filter-desc.svg";
import DirectionIcon from "icons/direction.svg";
import Button from "../Button";
import DropdownMenu from "../DropdownMenu";
import MenuItem from "../MenuItem";

const Popover = PopoverPrimitive.Root;

const PopoverTrigger = PopoverPrimitive.Trigger;

const PopoverAnchor = PopoverPrimitive.Anchor;

const PopoverContent = React.forwardRef<
    React.ElementRef<typeof PopoverPrimitive.Content>,
    React.ComponentPropsWithoutRef<typeof PopoverPrimitive.Content>
>(({ className, align = "center", sideOffset = 4, ...props }, ref) => (
    <PopoverPrimitive.Portal>
        <PopoverPrimitive.Content
            ref={ref}
            align={align}
            sideOffset={sideOffset}
            className={classNames(styles.popover, className)}
            {...props}
        />
    </PopoverPrimitive.Portal>
));
PopoverContent.displayName = PopoverPrimitive.Content.displayName;

interface Props {
    order: "ASC" | "DESC";
    orderBy: string;
    orderByKeys: { val: string; label: string }[];
    onKeyChange?: (key: string) => void;
    onOrderChange?: (order: "ASC" | "DESC") => void;
}

export const SortingBox = ({
    orderBy,
    order = "ASC",
    orderByKeys,
    onKeyChange,
    onOrderChange,
}: Props) => {
    const orderByLabel =
        orderByKeys.find((vals) => vals.val === orderBy)?.label || "";
    return (
        <Popover>
            <PopoverTrigger asChild>
                <Button
                    size="small"
                    color="alt"
                    variant="outline"
                    icon={
                        order === "ASC" ? <FilterAscIcon /> : <FilterDescIcon />
                    }
                >
                    <div className={styles.innerButtonContainer}>
                        {orderByLabel}
                        <DropdownIcon width={8} height={8} />
                    </div>
                </Button>
            </PopoverTrigger>

            <PopoverContent>
                <SortingContainer
                    order={order}
                    orderBy={orderByLabel}
                    orderByKeys={orderByKeys}
                    onKeyChange={onKeyChange}
                    onOrderChange={onOrderChange}
                />
            </PopoverContent>
        </Popover>
    );
};

const orderItems = [
    {
        val: "ASC",
        label: "Ascending",
    },
    {
        val: "DESC",
        label: "Descending",
    },
];

const SortingContainer = ({
    order,
    orderBy,
    orderByKeys,
    onKeyChange,
    onOrderChange,
}: Props) => {
    return (
        <div className={styles.sortingContainer}>
            <div className={styles.sortingTitle}>Sorting Options</div>
            <div className={styles.sortingBody}>
                <div className={styles.sortingOption}>
                    <div className={styles.sortingLabel}>
                        <SortingIcon />
                        Sort By
                    </div>
                    <div>
                        <DropdownMenu
                            sideOffset={4}
                            trigger={
                                <div className={styles.sortingOptions}>
                                    {orderBy}
                                    <DropdownIcon />
                                </div>
                            }
                            align={"start"}
                        >
                            {orderByKeys.map((orderByKey) => {
                                return (
                                    <MenuItem
                                        onClick={() =>
                                            onKeyChange?.(orderByKey.val)
                                        }
                                        key={orderByKey.val}
                                    >
                                        {orderByKey.label}
                                    </MenuItem>
                                );
                            })}
                        </DropdownMenu>
                    </div>
                </div>
                <div className={styles.sortingOption}>
                    <div className={styles.sortingLabel}>
                        <DirectionIcon />
                        Direction
                    </div>
                    <div>
                        <DropdownMenu
                            sideOffset={4}
                            trigger={
                                <div className={styles.sortingOptions}>
                                    {order}
                                    <DropdownIcon />
                                </div>
                            }
                            align={"start"}
                        >
                            {orderItems.map((orderItem) => {
                                return (
                                    <MenuItem
                                        onClick={() =>
                                            onOrderChange?.(
                                                orderItem.val as "ASC" | "DESC"
                                            )
                                        }
                                        key={orderItem.val}
                                    >
                                        {orderItem.label}
                                    </MenuItem>
                                );
                            })}
                        </DropdownMenu>
                    </div>
                </div>
            </div>
        </div>
    );
};
