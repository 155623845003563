import { PropsWithChildren, createContext, useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";
import { branchedView } from "../../shared/utils/utils";
import axios from "axios";
import { Edge, Node } from "reactflow";
import { CurrentViewState, SourceInfo } from "../../shared/utils/types";
import AxiosLoader from "../../shared/components/AxiosLoader";
import { buildDAG, getSourceDependencies } from "../utils";

export const CurrentViewContext = createContext<{
    nodes?: Node[];
    edges?: Edge[];
    sourceInfo?: SourceInfo;
    featureSetMap?: { [key: string]: string };
    viewInfo?: CurrentViewState;
    viewLoaded?: boolean;
}>({});

export const CurrentViewProvider = ({ children }: PropsWithChildren) => {
    const { branchName } = useParams();
    const loadFunc = useCallback(
        () => axios.get(branchedView(branchName)),
        [branchName]
    );
    return (
        <AxiosLoader
            onload={(viewInfo: CurrentViewState) => (
                <CurrentViewProviderLoaded viewInfo={viewInfo}>
                    {children}
                </CurrentViewProviderLoaded>
            )}
            loadFunc={loadFunc}
        />
    );
};

export const CurrentViewProviderLoaded = ({
    children,
    viewInfo,
}: PropsWithChildren<{ viewInfo: CurrentViewState }>) => {
    const { branchName } = useParams();

    Object.keys(viewInfo).forEach((key) => {
        if (
            viewInfo[key as keyof CurrentViewState] &&
            Array.isArray(viewInfo[key as keyof CurrentViewState])
        ) {
            viewInfo[key as keyof CurrentViewState]!.sort((a, b) =>
                a.name.localeCompare(b.name)
            );
        }
    });

    const viewInformation = useMemo(
        () => ({
            viewInfo,
            sourceInfo: { ...getSourceDependencies(viewInfo) },
            ...buildDAG(branchName || "", viewInfo),
        }),
        [branchName, viewInfo]
    );

    return (
        <CurrentViewContext.Provider value={viewInformation}>
            {children}
        </CurrentViewContext.Provider>
    );
};
