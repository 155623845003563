import { PropsWithChildren, useMemo } from "react";
import useSWR from "swr";

import { axiosFetcher } from "shared/utils/utils";

import { CurrentUserContext, CurrentUserState } from "./CurrentUserContext";

export const CurrentUserProvider = ({
    children,
    tiers,
    user,
    autoLogoutDuration,
    sso_url,
}: PropsWithChildren<Partial<CurrentUserState>>) => {
    const roleQuery = useSWR(
        user?.email
            ? [
                  "get",
                  `/api/v1/auth/role?user_email=${encodeURIComponent(
                      user.email
                  )}`,
              ]
            : null,
        axiosFetcher
    );

    const roleWithRulesQuery = useSWR(
        () => ["get", `/api/v1/auth/role/${roleQuery.data[0].id}`],
        axiosFetcher
    );

    const branches = useSWR(() => ["get", `/branch/list`], axiosFetcher);

    const value = useMemo(
        () => ({
            user,
            tiers,
            role: roleWithRulesQuery.data,
            branches: branches.data,
            autoLogoutDuration,
            sso_url,
        }),
        [user, tiers, roleWithRulesQuery.data, branches.data]
    );

    if (!user || !tiers) {
        // TODO(xiao) better error handling
        return null;
    }

    return (
        <CurrentUserContext.Provider value={value}>
            {children}
        </CurrentUserContext.Provider>
    );
};
