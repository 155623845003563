export interface Tier {
    id: number;
    name: string;
    tier_id: number;
}

export interface Branch {
    id: string;
    name: string;
    total_commits: number;
    last_modified: number;
    owner: string;
    created_at: number;
    base_branch_name: null | string;
    base_commit_id: null | string;
}

export interface User {
    id: number;
    email: string;
    first_name: string;
    last_name: string;
    role: Role /** TODO: This is new */;
    created_at: string /** TODO: This is new */;
}

export interface Team {
    id: number;
    name: string;
    users: User[];
    default_role: Role["id"];
}

export interface UserWithRole {
    user: User;
    role: Role;
}

export interface ErrorLog {
    id: number;
    content: string;
    logged_at: number;
    origin_type: string;
    origin_name: string;
    count?: number;
}

export interface ErrorOrigin {
    origin_type: string;
    origin_name: string;
}

export interface ExpectationResult {
    suite: string;
    expectation_type: string;
    threshold?: number;
    columns: string[];
    success_cnt: number;
    failure_cnt: number;
}

/**
 * Roles & Permissions
 */

export enum OrgPermission {
    ALL = "*",
    INVITE = "invite",
    CREATE_ROLE = "create_role",
    EDIT_ROLE = "edit_role",
    VIEW_ROLE = "view_role",
    ASSIGN_ROLE = "assign_role",
    PROVISION_BRANCH = "provision_branch",
    DELETE_BRANCH = "delete_branch",
    SET_DEFAULT_ROLE = "set_default_role",
    ASSUME_IDENTITY = "assume_identity",
    SERVICE_ACCOUNT = "service_account",
}

export enum BranchPermission {
    ALL = "*",
    MODIFY_ENVIRONMENT = "modify_environment",
    ADD_TAGS = "add_tags",
    DELETE_BRANCH_ONLY = "delete_branch_only",
    VIEW_BRANCH = "view_branch",
    BRANCH_ACCESS = "branch_access",
    VIEW_ENTITY_DEFINITION = "view_entity_definition",
    EDIT_ENTITY_DEFINITION = "edit_entity_definition",
    READ_ENTITY_DATA = "read_entity_data",
    WRITE_ENTITY_DATA = "write_entity_data",
    EXTRACT_HISTORICAL_FEATURES = "extract_historical_features",
}

export interface Rule {
    kind: "org" | "branch";
}

export interface OrgRule extends Rule {
    kind: "org";
    permissions: OrgPermission[];
}

export enum RuleType {
    ALLOW = "allow",
    DENY = "deny",
}

export interface TagBasedRule {
    permissions: BranchPermission[];
    rule_type: RuleType;
    tags: string[];
}

export interface BranchRule extends Rule {
    kind: "branch";
    branch: string;
    permissions?: BranchPermission[];
    tag_permissions?: TagBasedRule;
}

export type RolePermissionRule<T> = {
    name?: string;
    rule: T;
};

export interface Role {
    id: number;
    /** Name of the role */
    name: string;
    description?: string;
    /** Scopes */
    scopes: string[];
    /** Is the default org role */
    is_default: boolean;
    /** Is custom (if true, the role is mutable) */
    system_role: boolean;
    created_at: string;
    updated_at: string;
    revoked_at?: string;
}

export interface RoleWithCount {
    role: Role;
    num_users: number;
}

export interface RoleWithRules {
    role: Role;
    rules: RolePermissionRule<BranchRule | OrgRule>[];
}

export interface Token {
    /** Name of the token */
    token_name: string;
    /** Last 4 chars of the token */
    token_suffix: string;
    /** User info of the user that created the token */
    owner: string;
    /** Date string of when the token was created */
    created_timestamp: string;
    revoked_at?: string;
}

export type ServiceAccount = {
    id: number;
    name: string;
    description: string;
    created_at: string;
    owner: string;
    email?: string;
    revoked_at?: string;
    role: Role;
};

export enum ExtractHistoricalJobStatus {
    Created = "Initializing",
    InProgress = "in-progress",
    Processing = "Processing",
    Failed = "Failed",
    Cancelled = "Canceled",
    Finalizing = "Finalizing",
    Complete = "Completed",
}

export type ExtractHistoricalJob = {
    request_id: string;
    status: ExtractHistoricalJobStatus;
    submitted_by: string;
    is_service_account: boolean;
    started_at?: string;
    updated_at?: string;
    output_bucket: string;
    output_prefix: string;
    num_cells?: number;
    input_features: string[];
    output_features: string[];
    input_bucket: string;
    input_prefix: string;
    num_failed?: number;
    num_success?: number;
    workflow?: string;
    failure_rate?: number;
    completion_rate?: number;
};

export type ExtractHistoricalJobProgress = {
    request_id: string;
    output_bucket: string;
    output_prefix: string;
    /**
     * 0.0-1.0 float representing total completion of the job
     */
    completion_rate: number;
    /**
     * 0.0-1.0 float representing the percentage of processed data that failed
     */
    failure_rate: number;
    status: ExtractHistoricalJobStatus;
};
