import { Node, Edge, ReactFlowProvider } from "reactflow";

import { DAG } from "./dag/DAG";
import styles from "./styles/LineageSection.module.scss";
import { useContext, useEffect, useState } from "react";
import { CurrentViewContext } from "../../context/CurrentView/CurrentViewProvider";
import { Loader } from "./graph/Loader";
import {
    findConnectedAncestors,
    findConnectedDescendants,
} from "./dag/node.utils";

export interface DAG {
    nodes: Node[];
    edges: Edge[];
}

function LineageSection({
    focusedNode,
}: {
    focusedNode?: string;
}): JSX.Element {
    const { nodes, edges } = useContext(CurrentViewContext);
    let connectedEdges = edges;
    let connectedNodes = nodes;
    if (focusedNode && edges && nodes) {
        const { nodes: ancestorNodes, edges: ancestorEdges } =
            findConnectedAncestors(focusedNode, edges, nodes);
        const { nodes: descendantNodes, edges: descendantEdges } =
            findConnectedDescendants(focusedNode, edges, nodes);

        connectedNodes = [...ancestorNodes, ...descendantNodes];
        connectedEdges = [...ancestorEdges, ...descendantEdges];
    }
    const [loadTimeout, setLoadTimeout] = useState(false);
    useEffect(() => {
        const timer = setTimeout(() => setLoadTimeout(true), 10000);
        return () => clearTimeout(timer);
    }, []);
    return (
        <div className={styles.wrapper}>
            {(!nodes?.length || !edges?.length) && !loadTimeout ? (
                <Loader height={560} />
            ) : (
                <ReactFlowProvider>
                    <DAG
                        nodes={connectedNodes as Node[]}
                        edges={connectedEdges as Edge[]}
                        focusedNodes={[{ id: focusedNode || "" }]}
                    />
                </ReactFlowProvider>
            )}
        </div>
    );
}

export default LineageSection;
