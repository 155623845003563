import { useContext } from "react";
import { Column } from "shared/components/Table";
import {
    CurrentViewContext,
    useUpdateEntityParams,
} from "../../context/CurrentView";
import { DOC_LINKS } from "shared/constants/docs";
import { Dataset } from "shared/utils/types";
import { ConsoleDataTable } from "shared/componentsv2/ConsoleDataTable";
import { getColumns } from "./table/columns";

function DatasetsList({
    allDatasets,
    filteredDatasets,
}: {
    allDatasets: Dataset[];
    filteredDatasets: Dataset[];
}): JSX.Element {
    const { sourceInfo } = useContext(CurrentViewContext);
    const { updateEntityParams: navigate } = useUpdateEntityParams();

    const columns = getColumns(sourceInfo)

    return (
      <ConsoleDataTable 
        columns={columns}
        data={filteredDatasets}
        onRowClick={(row: any)=>{
            navigate("dataset", row.name);
        }}
        emptyState = {{
            dataExists: allDatasets.length > 0,
            emptyText: "No datasets found",
            learnMore: DOC_LINKS.dataset
        }}
        showFooter
        fixedHeader
        dataUnit="dataset"
        
        />
    );
}

export default DatasetsList;
