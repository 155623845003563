import { useSearchParams } from "react-router-dom";
import { CurrentViewContext } from "./CurrentViewProvider";
import { useContext } from "react";
import { FeatureProps } from "../../shared/utils/types";

const ENTITY_LIST = ["dataset", "feature", "featureset", "query_id"];

export const useUpdateEntityParams = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    const updateEntityParams = (
        entityType: string,
        entityName: string,
        featuresetName?: string
    ) => {
        const newSearchParams = new URLSearchParams();

        // Remove any existing entity params
        searchParams.forEach((val, key) => {
            if (!ENTITY_LIST.includes(key)) {
                newSearchParams.set(key, val);
            }
        });

        // Add new entity params based on rules
        if (entityType === "feature") {
            if (entityName && featuresetName) {
                newSearchParams.set("feature", entityName);
                newSearchParams.set("featureset", featuresetName);
            } else {
                console.error(
                    "Feature entity requires both feature and featureset names."
                );
                return;
            }
        } else if (ENTITY_LIST.includes(entityType)) {
            newSearchParams.set(entityType, entityName);
        }

        setSearchParams(newSearchParams);
    };

    const clearEntityParams = () => {
        const newSearchParams = new URLSearchParams();
        setSearchParams(newSearchParams);
    };

    return { updateEntityParams, clearEntityParams };
};

export const useValidEntity = () => {
    const { viewInfo } = useContext(CurrentViewContext);

    const getValidEntity = (
        entityType: string,
        entityName: string,
        featuresetName?: string
    ) => {
        if (entityType === "dataset") {
            return viewInfo?.datasets?.find((d) => d.name === entityName);
        }
        if (entityType === "featureset") {
            return viewInfo?.featuresets?.find((f) => f.name === entityName);
        }
        if (entityType === "feature") {
            const validFeatureset = viewInfo?.featuresets?.find(
                (f) => f.name === featuresetName
            );
            if (!validFeatureset) {
                return undefined;
            }

            const validFeature = validFeatureset.features.find(
                (f) => f.name === entityName
            );

            if (!validFeature) {
                return undefined;
            }

            return {
                feature: validFeature,
                featureset: validFeatureset,
                extractor: validFeatureset.extractors.find((e) =>
                    e.outputs?.includes(validFeature.name)
                ),
            } as FeatureProps;
        }
        return undefined;
    };

    return getValidEntity;
};
