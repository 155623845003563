import DescriptionIcon from "icons/alignment-left-bar.svg";
import styles from "../styles/EntityPage.module.scss";
import Metadata from "./Metadata";

const Description = ({ description }: { description: string }) => {
    return (
        <Metadata
        icon={<DescriptionIcon/>}
        keyLabel="Description"
        keyValue={
            description ? (
                description
            ) : (
                <span className={styles.none}>None</span>
            )
        }
        />
    );
};

export default Description;
