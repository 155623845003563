import CodeModal from "../components/CodeModal";
import CodeIcon from "icons/code.svg";
import styles from "./styles/ViewDefinition.module.scss";
import { useState } from "react";
import IconButton from "./IconButton";
import Tooltip from "./Tooltip";
import { createPortal } from "react-dom";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
    name: string;
    code: string;
    label?: string;
    tooltipContent?: string;
}

function ViewDefinition({
    name,
    label,
    code,
    tooltipContent = "View Definition",
    ...divProps
}: Props): JSX.Element {
    const [showCode, setShowCode] = useState(false);
    return (
        <div {...divProps}>
            {!label ? (
                <Tooltip content={tooltipContent}>
                    <IconButton
                        icon={<CodeIcon />}
                        onClick={() => setShowCode(true)}
                        size="small"
                        data-test={`viewdef-test`}
                    />
                </Tooltip>
            ) : (
                <div
                    className={styles.viewDef}
                    onClick={() => setShowCode(true)}
                >
                    <CodeIcon />
                    {label}
                </div>
            )}
            {showCode &&
                createPortal(
                    <CodeModal
                        code={code}
                        title={name}
                        onClose={() => setShowCode(false)}
                    />,
                    document.body
                )}
        </div>
    );
}

export default ViewDefinition;
