import { OrgPermission, BranchPermission } from "../models";

export const INIT_START_DATE = "1 day ago";
export const INIT_END_DATE = "Now";

export const pwdValidationRules = [
    {
        pattern: /[A-Z]+/,
        message: "Password must contain at least one uppercase letter",
    },
    {
        pattern: /[a-z]+/,
        message: "Password must contain at least one lowercase letter",
    },
    { pattern: /\d+/, message: "Password must contain at least one digit" },
    {
        pattern: /[@$!%*?&_]+/,
        message: "Password must contain at least one from @$!%*?&_",
    },
    {
        pattern: /.{8,}/,
        message: "Password must be at least 8 characters long",
    },
];

export const BRANCH_PERMISSION_LABELS: Record<BranchPermission, string> = {
    [BranchPermission.ALL]: "All actions",
    [BranchPermission.VIEW_BRANCH]: "View branch",
    [BranchPermission.BRANCH_ACCESS]: "Grant branch access",
    [BranchPermission.DELETE_BRANCH_ONLY]: "Delete branch",
    [BranchPermission.MODIFY_ENVIRONMENT]: "Modify branch environment",
    [BranchPermission.VIEW_ENTITY_DEFINITION]: "Read definition",
    [BranchPermission.EDIT_ENTITY_DEFINITION]: "Edit definition",
    [BranchPermission.READ_ENTITY_DATA]: "Read data",
    [BranchPermission.WRITE_ENTITY_DATA]: "Write data",
    [BranchPermission.ADD_TAGS]: "Add tags",
    [BranchPermission.EXTRACT_HISTORICAL_FEATURES]:
        "Extract historical features",
};

export const ORG_PERMISSION_GROUPS: Record<string, OrgPermission[]> = {
    // all: [OrgPermission.ALL],
    roles: [
        OrgPermission.CREATE_ROLE,
        OrgPermission.EDIT_ROLE,
        OrgPermission.VIEW_ROLE,
    ],
    users: [OrgPermission.ASSIGN_ROLE],
    default_role: [OrgPermission.SET_DEFAULT_ROLE],
    tokens: [OrgPermission.ASSUME_IDENTITY],
    service_accounts: [OrgPermission.SERVICE_ACCOUNT],
};

export const ORG_PERMISSION_LABELS: Record<string, string> = {
    roles: "View and manage roles.",
    users: "Assign roles to members.",
    default_role: "Update the default org role.",
    tokens: "Can view and revoke tokens created by other users.",
    service_accounts: "Can manage service accounts.",
};

export const FILTER_META_SEPARATOR = "~~~";
export const VERSION_SEPARATOR = "@";
export const LOGOUT_INACTIVE_TIME = 1800000;
