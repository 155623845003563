import { ForwardedRef, forwardRef } from "react";
import classnames from "classnames";
import * as RadixAvatar from "@radix-ui/react-avatar";
import CubeIcon from "icons/cube.svg";

import styles from "./styles/Avatar.module.scss";

type Props = {
    name: string;
    shadowed?: boolean;
    size?: "small" | "medium" | "large" | "xlarge"; // small is used in table cells, medium in navbar, large in settings page
    isServiceAccount?: boolean;
};

function Avatar(
    { name, size = "small", isServiceAccount, ...rest }: Props,
    ref: ForwardedRef<HTMLDivElement>
): JSX.Element {
    const ch = (name && name[0].toUpperCase()) || "";
    const classes = classnames(styles.root, styles[size]);
    return (
        <RadixAvatar.Root {...rest} ref={ref} className={classes}>
            <RadixAvatar.Fallback className={styles.flexItem}>
                {isServiceAccount ? <CubeIcon /> : ch}
            </RadixAvatar.Fallback>
        </RadixAvatar.Root>
    );
}
Avatar.displayName = "Avatar";

export default forwardRef(Avatar);
