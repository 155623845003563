import styles from "./SourcesPage.module.scss";
import SourceIcon from "icons/source.svg";
import KafkaIcon from "icons/kafka.svg";
import MySQLIcon from "icons/mysql.svg";
import PostgresIcon from "icons/postgres.svg";
import SnowflakeIcon from "icons/snowflake.svg";
import BigQueryIcon from "icons/bigquery.svg";
import WebhookIcon from "icons/webhook.svg";
import AWSIcon from "icons/aws.svg";
import { stringCompare } from "shared/utils/utils";
import Table, { Column } from "shared/components/Table";
import AxiosLoader from "shared/components/AxiosLoader";
import axios from "axios";
import { useCallback } from "react";
import { SOURCES_NAV } from "shared/constants/navigation";
import { DOC_LINKS } from "shared/constants/docs";
import { SourceExt } from "shared/utils/types";
import ViewDefinition from "shared/components/ViewDefinition";

function SourcesPage(): JSX.Element {
    return (
        <div className={styles.gridContainer}>
            <div className={styles.flexContainer}>
                <h2 className={styles.title}>
                    {SOURCES_NAV.icon}
                    {SOURCES_NAV.title}
                </h2>
                <SourcesTable />
            </div>
        </div>
    );
}

const SourcesTable = () => {
    const loadFunc = useCallback(() => axios.post("/sources"), []);
    return (
        <AxiosLoader
            onload={(sources: SourceExt[]) => (
                <SourcesTableLoaded sources={sources} />
            )}
            loadFunc={loadFunc}
        />
    );
};

function SourcesTableLoaded({
    sources: allSources,
}: {
    sources: SourceExt[];
}): JSX.Element {
    const columns: Column<SourceExt>[] = [
        {
            header: "Type",
            renderFunc: (s) => <SourceTypeIcon type={Object.keys(s.db)[0]} />,
        },
        {
            header: "Source Name",
            renderFunc: (s) => s.name,
            sortFunc: (x, y) => stringCompare(x.name, y.name),
        },
        {
            header: false,
            renderFunc: (s) => (
                <ViewDefinition
                    tooltipContent={"View Metadata"}
                    code={JSON.stringify(s.db, null, "\t")}
                    name={`${s.name}`}
                />
            ),
        },
    ];

    return (
        <Table
            className={styles.table}
            data={allSources}
            columns={columns}
            rowKeyFunc={(s) => s.name}
            dataUnit="Source"
            emptyText={"You haven't connected a source to fennel yet"}
            learnMore={DOC_LINKS.source}
        />
    );
}

function SourceTypeIcon({ type }: { type: string }): JSX.Element {
    let icon = <SourceIcon />;
    switch (type) {
        case "S3":
            icon = <AWSIcon />;
            break;
        case "Bigquery":
            icon = <BigQueryIcon />;
            break;
        case "Snowflake":
            icon = <SnowflakeIcon />;
            break;
        case "MySql":
            icon = <MySQLIcon />;
            break;
        case "Postgres":
            icon = <PostgresIcon />;
            break;
        case "Webhook":
            icon = <WebhookIcon />;
            break;
        case "Kafka":
            icon = <KafkaIcon />;
            break;
    }
    return (
        <div>
            {icon}
            <span>{type}</span>
        </div>
    );
}

export default SourcesPage;
