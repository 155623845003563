// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".fL4Hn907MOyPrsggvxWj{display:flex;flex-direction:column;align-items:center}.HsctavyzUYMzoRIDECcQ{display:flex;align-items:center;color:#e5412e}.PaI3tqmFU730Gy5vjmgF{margin-top:8px;font-weight:700;font-size:24px;line-height:24px;color:var(--muted-foreground)}.pHNOpV8CZT0Ucpv6quKn{margin-top:12px;font-weight:600;font-size:16px;line-height:24px;color:var(--foreground)}.Ltcn4MTCoOzNtoJt8Lmg{margin-top:32px;display:flex;align-items:center;gap:8px}", "",{"version":3,"sources":["webpack://./src/console/shared/components/styles/LoadingError.module.scss"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,qBAAA,CACA,kBAAA,CAGJ,sBACI,YAAA,CACA,kBAAA,CACA,aAAA,CAGJ,sBACI,cAAA,CAEA,eAAA,CACA,cAAA,CACA,gBAAA,CACA,6BAAA,CAGJ,sBACI,eAAA,CAEA,eAAA,CACA,cAAA,CACA,gBAAA,CACA,uBAAA,CAGJ,sBACI,eAAA,CACA,YAAA,CACA,kBAAA,CACA,OAAA","sourcesContent":[".container {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n}\n\n.alertIcon {\n    display: flex;\n    align-items: center;\n    color: #E5412E;\n}\n\n.wrong {\n    margin-top: 8px;\n\n    font-weight: 700;\n    font-size: 24px;\n    line-height: 24px;\n    color: var(--muted-foreground);\n}\n\n.message {\n    margin-top: 12px;\n\n    font-weight: 600;\n    font-size: 16px;\n    line-height: 24px;\n    color: var(--foreground);\n}\n\n.button {\n    margin-top: 32px;\n    display: flex;\n    align-items: center;\n    gap: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "fL4Hn907MOyPrsggvxWj",
	"alertIcon": "HsctavyzUYMzoRIDECcQ",
	"wrong": "PaI3tqmFU730Gy5vjmgF",
	"message": "pHNOpV8CZT0Ucpv6quKn",
	"button": "Ltcn4MTCoOzNtoJt8Lmg"
};
export default ___CSS_LOADER_EXPORT___;
