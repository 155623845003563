import { createContext } from "react";
import { Branch, RoleWithRules, Tier, User } from "shared/models";

export type CurrentUserState = {
    user?: User;
    tiers?: Tier[];
    role?: RoleWithRules;
    branches?: Branch[];
    autoLogoutDuration?: number;
    sso_url?: string;
};

export const CurrentUserContext = createContext<CurrentUserState>({});
