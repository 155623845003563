import FeaturesetIcon from "icons/featureset.svg";
import { stringCompare } from "../utils/utils";
import { Column } from "./Table";
import { DOC_LINKS } from "../constants/docs";
import { useUpdateEntityParams } from "../../context/CurrentView";
import {
    EntityDescriptor,
    EntityOwner,
    EntityTable,
} from "shared/components/EntityTable";
import { Featureset } from "shared/utils/types";
import { ConsoleDataTable } from "shared/componentsv2/ConsoleDataTable";
import { ColumnDef } from "@tanstack/react-table";
import { DataTableColumnHeader } from "shared/componentsv2/DataTableColumnHeader";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
    featuresets: Featureset[];
    isLearnMore: boolean;
    windowed?: boolean;
    emptyText?: string;
}

function FeaturesetsTable({
    featuresets,
    className,
    windowed,
    emptyText,
    isLearnMore,
    ...rest
}: Props): JSX.Element {
    const { updateEntityParams } = useUpdateEntityParams();
    const columns: ColumnDef<Featureset>[] = [
        {
            accessorKey: "name",
            id: "featureset",
            header: ({ column }) => {
                return (
                    <DataTableColumnHeader column={column}  title="Featureset"/>
                )
              },
            cell: ({row: {original: fs}}) => {
                return <EntityDescriptor
                    name={fs.name}
                    entityIcon={<FeaturesetIcon />}
                    tags={fs.tags}
                    metaDescription={`${fs.features?.length} features`}
                />
            },
            size: 500,
        },
        {
            accessorKey: "owner",

            header: ({ column }) => {
                return (
                    <DataTableColumnHeader column={column}  title="Owner"/>
                )
              },
            cell: ({row: {original: fs}}) => {
                return <EntityOwner owner={fs.owner || ""} />
            },
        },
    ];

    return (
        <ConsoleDataTable
            data={featuresets}
            columns={columns}
            onRowClick={(row) => {
                updateEntityParams("featureset", row.name);
            }}
            dataUnit="featureset"
            showFooter
            fixedHeader
            emptyState = {{
                dataExists: !isLearnMore,
                emptyText: emptyText,
                learnMore: DOC_LINKS.featureset
            }}
            {...rest}
        />
    );
}

export default FeaturesetsTable;
