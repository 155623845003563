import { createContext } from "react";

interface QueryOfflineContextProps {
    orderBy: string;
    order: string;
}

export const QueryOfflineContext = createContext<QueryOfflineContextProps>(
    {} as QueryOfflineContextProps
);
