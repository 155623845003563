import { useCallback, useContext, useEffect, useState } from "react";
import { CurrentViewContext } from "../../context/CurrentView";
import { FILTER_META_SEPARATOR } from "shared/constants/constants";
import DatasetFilter from "shared/components/search/SourceFilter";
import FeatureMetricsFilter from "shared/components/search/FeatureMetricsFilters";
import DatasetIcon from "icons/dataset.svg";
import FeatureIcon from "icons/feature.svg";
import FeaturesetIcon from "icons/featureset.svg";
import { useSearchParams } from "react-router-dom";
import { Filter } from "shared/components/search/filters";

export const useSearchConfig = () => {
    const { viewInfo } = useContext(CurrentViewContext);
    if (!viewInfo) {
        return undefined;
    }
    const { datasets, featuresets } = viewInfo;

    if (!datasets || !featuresets) {
        return undefined;
    }
    const allDatasets = new Set(datasets.flatMap((d) => d.name));
    const datasetNames = Array.from(allDatasets.keys()).sort();
    const datasetNamesConfig = {
        icon: DatasetIcon,
        propertyName: "Dataset",
        kind: {
            kind: "single-select" as const,
            options: datasetNames.map((t) => ({ key: t, name: t, value: t })),
            valueCategory: "dataset",
            component: DatasetFilter,
            relationship: "has-one" as const,
        },
    };

    const allFeatures = featuresets.flatMap(
        ({ features, name: featureset, owner }) =>
            features.map((f) => ({ ...f, featureset, owner }))
    );

    const allExtractors = featuresets.flatMap(
        ({ extractors, name: featureset }) =>
            extractors.map((e) => ({ ...e, featureset }))
    );

    const extractorConfig = {
        icon: FeaturesetIcon,
        propertyName: "Extractor",
        kind: {
            kind: "single-select" as const,
            options: allExtractors.map((e) => ({
                key: e.name + "_" + e.featureset,
                name: e.name,
                value: e.name + FILTER_META_SEPARATOR + e.featureset,
                metadata: { featureset: e.featureset },
                metadataSearch: true,
            })),
            valueCategory: "extractor",
            component: FeatureMetricsFilter,
            relationship: "has-one" as const,
        },
    };

    const featureConfig = {
        icon: FeatureIcon,
        propertyName: "Feature",
        kind: {
            kind: "single-select" as const,
            options: allFeatures.map((f) => ({
                key: f.name + "_" + f.featureset,
                name: f.name,
                value: f.name + FILTER_META_SEPARATOR + f.featureset,
                metadata: { ...f, tags: "", lineage_tags: "" },
                metadataSearch: true,
            })),
            valueCategory: "feature",
            component: FeatureMetricsFilter,
            relationship: "has-one" as const,
        },
    };
    const filterOrder = ["dataset", "feature", "extractor"];

    return {
        filterOrder,
        filterConfigs: {
            dataset: datasetNamesConfig,
            feature: featureConfig,
            extractor: extractorConfig,
        },
    };
};

export const useTargetNode = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const { viewInfo } = useContext(CurrentViewContext);

    const dataset_name = searchParams.get("dataset");
    const feature_name = searchParams.get("feature");
    const featureset_name = searchParams.get("featureset");
    const node_id = searchParams.get("nodeId");
    const [defaultFilters, setDefaultFilters] = useState<Filter[]>([]);
    const [targetNode, setTargetNode] = useState<string | undefined>();

    useEffect(() => {
        if (dataset_name) {
            setDefaultFilters([
                {
                    key: "dataset",
                    value: [dataset_name],
                    verb: "is",
                },
            ]);
        } else if (feature_name && featureset_name) {
            setDefaultFilters([
                {
                    key: "feature",
                    value: [
                        feature_name + FILTER_META_SEPARATOR + featureset_name,
                    ],
                    verb: "is",
                },
            ]);
        } else if (node_id && featureset_name) {
            const extractorName = node_id.split(".").slice(1).join(".");
            setDefaultFilters([
                {
                    key: "extractor",
                    value: [
                        (extractorName || "-") +
                            FILTER_META_SEPARATOR +
                            featureset_name,
                    ],
                    verb: "is",
                },
            ]);
        } else {
            setDefaultFilters([]);
        }
    }, [dataset_name, feature_name, featureset_name, node_id]);

    useEffect(() => {
        if (dataset_name) {
            setTargetNode(getNodeIdFromEntityName("dataset", dataset_name));
        } else if (feature_name && featureset_name) {
            setTargetNode(
                getNodeIdFromEntityName(
                    "feature",
                    feature_name,
                    featureset_name
                )
            );
        } else if (node_id && featureset_name) {
            setTargetNode(node_id);
        } else if (!featureset_name) {
            setTargetNode(undefined);
        }
    }, [dataset_name, feature_name, featureset_name, node_id]);

    const getNodeIdFromEntityName = useCallback(
        (entityType: string, entityName: string, featuresetName?: string) => {
            if (entityType === "dataset") {
                return entityName;
            } else {
                const validFeatureset = viewInfo?.featuresets?.find(
                    (fs) => fs.name === featuresetName
                );
                const extractor = validFeatureset?.extractors.find((e) =>
                    e.outputs?.includes(entityName)
                );
                return extractor?.name
                    ? featuresetName + "." + extractor.name
                    : featuresetName;
            }
        },
        [viewInfo?.featuresets]
    );

    return {
        setTargetNode,
        defaultFilters,
        targetNode,
        getNodeIdFromEntityName,
    };
};
