import styles from "./styles/FeatureDetail.module.scss";
import FeatureIcon from "icons/feature.svg";
import GraphLegends from "shared/components/graph/GraphLegends";
import ArrowIcon from "icons/arrow-narrow-up-right.svg";
import { useEffect, useMemo, useState } from "react";
import axios, { AxiosResponse } from "axios";
import { branchedFeatureLink, branchedMetricsLink } from "shared/utils/utils";
import { Link, useParams } from "react-router-dom";
import {
    convertToDate,
    toISOString,
} from "shared/components/date-picker/date-types";
import { INIT_END_DATE, INIT_START_DATE } from "shared/constants/constants";
import {
    DataPoint,
    PromResponse,
    getMinMax,
    parsePromResponse,
} from "shared/utils/prometheus";
import {
    DEFAULT_STROKE,
    FULL_GRAPH_HEIGHT,
    FULL_GRAPH_WIDTH,
} from "shared/components/graph/Graph";
import NumberSeries from "shared/components/graph/NumberSeries";
import MultiNumberSeries from "shared/components/graph/MultiNumberSeries";
import {
    Description,
    Owner,
    Featureset as FeaturesetMeta,
    Tags,
    Extractor as ExtractorMeta,
    ToLineage,
} from "shared/components/entityPage/metadataComponents";
import { Extractor, Feature, Featureset } from "shared/utils/types";
import InspectorPage from "../inspector/shared/InspectorPanel";
import { cn } from "shared/utils/styling";

interface Props {
    feature: Feature;
    extractor: Extractor;
    featureset: Featureset;
}

function FeatureLoaded({ feature, extractor, featureset }: Props): JSX.Element {
    return (
        <InspectorPage
            header={{
                title: feature.name,
                icon: <FeatureIcon />,
                actions: [<ToLineage key="to-lineage" />],
            }}
            sections={[
                {
                    title: "Metadata",
                    section: (
                        <MetadataSection
                            feature={feature}
                            featureset={featureset}
                            extractor={extractor}
                        />
                    ),
                },
                {
                    title: "Activity",
                    subtitle: "Last 24h",
                    titleAction: (
                        <Link
                            className={cn("flex",styles.metadataTitleLink)}
                            to={`./metrics?feature=${feature.name}&featureset=${featureset.name}`}
                        >
                            View Metrics
                            <ArrowIcon />
                        </Link>
                    ),
                    section: (
                        <ActivitySection
                            featureName={feature.name}
                            featuresetName={featureset.name}
                        />
                    ),
                },
                {
                    title: "Distribution",
                    subtitle: "Last 24h",
                    titleAction: (
                        <Link
                            className={cn("flex",styles.metadataTitleLink)}
                            to={`./metrics?feature=${feature.name}&featureset=${featureset.name}`}
                        >
                            View Metrics
                            <ArrowIcon />
                        </Link>
                    ),
                    section: (
                        <DistributionSection
                            featureName={feature.name}
                            featuresetName={featureset.name}
                        />
                    ),
                },
            ]}
        />
    );
}

function MetadataSection({ feature, featureset, extractor }: Props) {
    return (
        <>
            <Description description={feature.description} />
            <FeaturesetMeta name={featureset.name} />
            <ExtractorMeta extractor={extractor} />
            <Owner owner={feature.owner || featureset.owner} />
            <Tags tags={feature.tags} lineage_tags={feature.lineage_tags} />
        </>
    );
}

interface DistResponse {
    mean: PromResponse;
    lower: PromResponse;
    upper: PromResponse;
}

interface DistData {
    mean: DataPoint[];
    lower: DataPoint[];
    upper: DataPoint[];
}

function ActivitySection({
    featuresetName,
    featureName,
}: {
    featuresetName: string;
    featureName: string;
}): JSX.Element {
    const { branchName } = useParams();
    const startDate = convertToDate(INIT_START_DATE);
    const endDate = convertToDate(INIT_END_DATE);
    const [isLoaded, setIsLoaded] = useState(false);

    return (
        <div className={styles.activitySection}>
            <div>
                <div className={styles.content}>
                    <NumberSeries
                        startDate={startDate}
                        endDate={endDate}
                        height={FULL_GRAPH_HEIGHT}
                        width={FULL_GRAPH_WIDTH}
                        lineLabel="Extracted"
                        stroke={DEFAULT_STROKE}
                        loadFunc={() => {
                            return axios
                                .post(
                                    branchedMetricsLink(branchName, "feature"),
                                    {
                                        featureset: featuresetName,
                                        feature: featureName,
                                        start: toISOString(startDate),
                                        end: toISOString(endDate),
                                    }
                                )
                                .then((resp: AxiosResponse<PromResponse>) =>
                                    parsePromResponse(resp.data)
                                )
                                .finally(() => setIsLoaded(true));
                        }}
                        isLoaded={isLoaded}
                        hideTooltip
                    />
                </div>
            </div>
        </div>
    );
}

function DistributionSection({
    featuresetName,
    featureName,
}: {
    featuresetName: string;
    featureName: string;
}): JSX.Element {
    const { branchName } = useParams();
    const [distData, setDistData] = useState<DistData>();

    const startDate = convertToDate(INIT_START_DATE);
    const endDate = convertToDate(INIT_END_DATE);

    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        const featureLink = branchedFeatureLink(
            branchName,
            featuresetName,
            featureName
        );
        const link = `${featureLink}/distributions`;
        axios
            .post(link, {
                featureset: featuresetName,
                feature: featureName,
                start: toISOString(startDate),
                end: toISOString(endDate),
            })
            .then((resp: AxiosResponse<DistResponse>) => {
                const data = {
                    mean: parsePromResponse(resp.data.mean),
                    lower: parsePromResponse(resp.data.lower),
                    upper: parsePromResponse(resp.data.upper),
                };
                setDistData(data);
            })
            .finally(() => setIsLoaded(true));
    }, []);
    const lMinMax = useMemo(() => getMinMax(distData?.lower || []), [distData]);
    const uMinMax = useMemo(() => getMinMax(distData?.upper || []), [distData]);
    const minMax = lMinMax && uMinMax && { min: lMinMax.min, max: uMinMax.max };

    return (
        <div>
            <div className={styles.content}>
                {
                    <MultiNumberSeries
                        width={FULL_GRAPH_WIDTH}
                        height={FULL_GRAPH_HEIGHT}
                        startDate={startDate}
                        endDate={endDate}
                        yDomain={minMax}
                        annotationLabelFormat={(v) =>
                            `${Math.round(v * 100) / 100}`
                        }
                        configs={{
                            mean: {
                                stroke: "#655ce9",
                                lineLabel: "Mean",
                            },
                            lower: {
                                stroke: "#261584",
                                lineLabel: "-σ",
                                strokeStyle: "dotted",
                            },
                            upper: {
                                stroke: "#261584",
                                lineLabel: "+σ",
                                strokeStyle: "dotted",
                            },
                        }}
                        data={{ ...distData }}
                        isLoaded={isLoaded}
                        hideTooltip
                    />
                }
            </div>
        </div>
    );
}

export default FeatureLoaded;
