import { useCallback, useState } from "react";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import axios, { AxiosError } from "axios";

import { toast_json_error, toast_success } from "shared/utils/toast";
import { useCurrentUser, useHasPermission } from "../../../context/CurrentUser";

import ShieldIcon from "icons/shield.svg";

import Button from "shared/components/Button";
import { Field, TextInput } from "shared/components/TextInput";
import UserDisplay from "shared/components/UserDisplay";
import PageTitle from "../shared/PageTitle";
import UpdatePasswordForm from "./UpdatePasswordForm";

import styles from "./styles/UserSettings.module.scss";
import RoleOverviewDialog from "../OrganizationRoleSettings/RoleOverviewDialog";
import ConfigureMFA from "./ConfigureMFA";
import ConfigureAutoLogout from "./ConfigureAutoLogout";

type ProfileFormValues = {
    first_name: string;
    last_name: string;
};

function UserSettings() {
    const { user, role } = useCurrentUser();
    const permissions_map = useHasPermission();
    const [showRoleOverview, setShowRoleOverview] = useState<boolean>(false);

    const {
        control,
        handleSubmit,
        reset,
        formState: { isDirty, isValid },
    } = useForm<ProfileFormValues>({
        defaultValues: user || {},
    });

    const [submitting, setIsSubmitting] = useState<boolean>(false);

    const onSubmit: SubmitHandler<ProfileFormValues> = useCallback(
        async (values) => {
            try {
                setIsSubmitting(true);
                await axios.post("/users/me/change_name", values, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                });

                setIsSubmitting(false);
                toast_success("Profile updated.");
                reset();
            } catch (e) {
                setIsSubmitting(false);
                toast_json_error(
                    e as AxiosError<{ detail?: string }>,
                    "Something went wrong"
                );
            }
        },
        []
    );

    return (
        <div className={styles.root}>
            <div className={styles.user_header}>
                <UserDisplay
                    name={`${user?.first_name} ${user?.last_name}`}
                    meta={user?.email}
                    size="large"
                />
                <Button
                    icon={<ShieldIcon />}
                    onClick={() => setShowRoleOverview(true)}
                    size="small"
                    variant="outline"
                >
                    {role?.role.name}
                </Button>
                <RoleOverviewDialog
                    open={showRoleOverview}
                    onOpenChange={(open) => setShowRoleOverview(open)}
                    role_id={role?.role.id}
                    viewOnly
                />
            </div>
            <section className={styles.general_settings}>
                <PageTitle
                    title="Profile"
                    description="Configure your basic profile information."
                />
                <form
                    className={styles.profile_form}
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <Controller
                        control={control}
                        name="first_name"
                        rules={{ required: true }}
                        render={({ field }) => (
                            <Field label="First Name">
                                <TextInput {...field} />
                            </Field>
                        )}
                    />
                    <Controller
                        control={control}
                        name="last_name"
                        rules={{ required: true }}
                        render={({ field }) => (
                            <Field label="Last Name">
                                <TextInput {...field} />
                            </Field>
                        )}
                    />
                    <div className={styles.form_footer}>
                        <Button
                            disabled={!isDirty || !isValid}
                            loading={submitting}
                            color="primary"
                            type="submit"
                        >
                            Save
                        </Button>
                    </div>
                </form>
            </section>
            <section>
                <PageTitle
                    title="Security"
                    description="Configure security settings and default preferences for your account."
                />
                <UpdatePasswordForm />
                <ConfigureMFA />
                {permissions_map.isSuperAdmin && <ConfigureAutoLogout />}
            </section>
        </div>
    );
}

export default UserSettings;
