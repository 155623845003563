import { useCallback } from "react";
import axios, { AxiosError } from "axios";
import { toast_json_error, toast_success } from "shared/utils/toast";
import { DeleteConfirmDialog } from "shared/components/DeleteConfirmDialog";

const ServiceAccountDeleteDialog = ({
    onSuccess,
    serviceAccountId,
}: {
    onSuccess: () => void;
    serviceAccountId?: number;
}) => {
    const onSubmit = useCallback(async () => {
        try {
            await axios.delete(
                `/api/v1/auth/service_account/${serviceAccountId}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );

            toast_success("Service account deleted.");
            onSuccess();
        } catch (e) {
            toast_json_error(
                e as AxiosError<{ detail?: string }>,
                "Something went wrong"
            );
        }
    }, [onSuccess, serviceAccountId]);

    return (
        <DeleteConfirmDialog
            description={
                "This action cannot be undone, and all tokens and services using this service account will stop functioning."
            }
            onSubmit={onSubmit}
        />
    );
};

export default ServiceAccountDeleteDialog;
