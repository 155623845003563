import classnames from "classnames";
import { useCallback, useEffect, useState } from "react";
import { maybePlural } from "../utils/utils";
import ChevronVerticalIcon from "icons/chevron-selector-vertical.svg";
import ChevronDownIcon from "icons/chevron-down.svg";
import ChevronUpIcon from "icons/chevron-up.svg";

import styles from "./styles/Table.module.scss";
import EmptyState from "./EmptyState";

const SortIcon = ({ order }: { order?: string }) => {
    if (order == "ascending") {
        return (
            <div className={styles.sortControl}>
                <ChevronUpIcon width={12} height={12} viewBox="0 0 24 24" />
            </div>
        );
    } else if (order == "descending") {
        return (
            <div className={styles.sortControl}>
                <ChevronDownIcon width={12} height={12} viewBox="0 0 24 24" />
            </div>
        );
    }
    return (
        <div className={styles.sortControl}>
            <ChevronVerticalIcon width={12} height={12} viewBox="0 0 24 24" />
        </div>
    );
};

export interface Column<T> {
    /* header should be unique, it's used in sorting */
    header: string | false;
    sortFunc?: (x: T, y: T) => number;
    renderFunc: (row: T) => React.ReactNode;
    headerRenderFunc?: () => React.ReactNode;
}

export interface Props<T> extends React.HTMLAttributes<HTMLDivElement> {
    data: T[];
    emptyText?: string;
    rowKeyFunc: (row: T) => React.Key;
    onRowClick?: (row: T) => void;
    columns: Column<T>[];
    windowed?: boolean;
    /* shows in the bottom, like 10 total datasets or 12 total features */
    dataUnit: string;
    /* default is just adding s to the dataUnit */
    dataUnitPlural?: string;
    fixedHeader?: boolean;
    divided?: boolean;
    isLoading?: boolean;
    learnMore?: string;
}

interface SortBy {
    colHeader: string;
    order: "ascending" | "descending";
}

function Table<T>({
    data,
    emptyText,
    columns,
    rowKeyFunc,
    className,
    dataUnit,
    dataUnitPlural,
    onRowClick,
    windowed,
    fixedHeader,
    isLoading,
    learnMore,
    ...rest
}: Props<T>): JSX.Element {
    const [sortBy, setSortBy] = useState<SortBy>();
    const [rows, setRows] = useState<T[]>(data);

    const colspans: number[] = columns.map((col) =>
        col.header != false ? 1 : 0
    );
    colspans.forEach((v, idx) => {
        if (v > 0) {
            let i = idx + 1;
            while (i < colspans.length && colspans[i] == 0) {
                i += 1;
            }
            colspans[idx] = i - idx;
        }
    });

    useEffect(() => {
        if (!sortBy) {
            setRows(data);
        } else {
            const sortByCol = columns.find(
                (col) => col.header == sortBy.colHeader
            );
            if (sortByCol && sortByCol.sortFunc) {
                const sortFunc = sortByCol.sortFunc;
                const rows = [...data];
                rows.sort((x, y) => {
                    if (sortBy.order == "ascending") {
                        return sortFunc(x, y);
                    }
                    return sortFunc(y, x);
                });
                setRows(rows);
            }
        }
    }, [sortBy, columns, data]);

    const renderTable = useCallback(() => {
        if (!data.length) {
            return (
                <EmptyState
                    text={
                        emptyText ||
                        `No ${maybePlural(
                            data.length,
                            dataUnit,
                            dataUnitPlural
                        )}`
                    }
                    loading={isLoading}
                    learnMore={learnMore}
                />
            );
        }
        return (
            <table
                className={classnames(styles.table, {
                    [styles.dividedTable]: rest.divided,
                })}
            >
                <thead>
                    <tr>
                        {columns
                            .map((col, idx) => ({
                                col: col,
                                span: colspans[idx],
                            }))
                            .filter((pair) => pair.span > 0)
                            .map((pair) => (
                                <th
                                    key={pair.col.header || ""}
                                    colSpan={pair.span}
                                    className={
                                        fixedHeader
                                            ? styles.fixedHeaderCell
                                            : ""
                                    }
                                >
                                    <div
                                        className={classnames(
                                            styles.headerCell,
                                            {
                                                [styles.clickable]:
                                                    !!pair.col.sortFunc,
                                            }
                                        )}
                                        onClick={() =>
                                            setSortBy({
                                                colHeader:
                                                    pair.col.header || "",
                                                order:
                                                    sortBy?.order ===
                                                    "ascending"
                                                        ? "descending"
                                                        : "ascending",
                                            })
                                        }
                                    >
                                        {pair.col.headerRenderFunc
                                            ? pair.col.headerRenderFunc()
                                            : pair.col.header}
                                        {pair.col.sortFunc ? (
                                            <SortIcon
                                                order={
                                                    sortBy?.colHeader ===
                                                    pair.col.header
                                                        ? sortBy?.order
                                                        : undefined
                                                }
                                            />
                                        ) : null}
                                    </div>
                                </th>
                            ))}
                    </tr>
                </thead>
                <tbody>
                    {rows.map((row, index) => (
                        <tr
                            key={rowKeyFunc(row)}
                            onClick={() => onRowClick?.(row)}
                            className={classnames({
                                [styles.clickable]: !!onRowClick,
                            })}
                            data-test={`row-${index}-test`}
                        >
                            {columns.map((col, idx) => (
                                <td key={idx} data-test={`cell-${idx}-test`}>
                                    {col.renderFunc(row)}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        );
    }, [sortBy, columns, rows, data, dataUnit, dataUnitPlural]);

    if (windowed) {
        return (
            <div className={classnames(styles.container, className)} {...rest}>
                <div
                    className={classnames(styles.scrollContainer, {
                        [styles.empty]: !data.length,
                    })}
                >
                    {renderTable()}
                </div>
                {data.length ? (
                    <div className={styles.footer}>
                        {data.length} total{" "}
                        {maybePlural(data.length, dataUnit, dataUnitPlural)}
                    </div>
                ) : null}
            </div>
        );
    }

    return (
        <div className={classnames(styles.container, className)} {...rest}>
            {renderTable()}
        </div>
    );
}

export default Table;
